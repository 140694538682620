import {
    IconButton,
    Stack,
} from '@mui/material'

import Twitter from '@mui/icons-material/Twitter';
import Facebook from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';

const SocialIcons = ( props ) => {
    return (
        <Stack direction="row" {...props}>
            <IconButton aria-label="instagram" href="http://instagram.com/jhareexpress/">
                <Instagram />
            </IconButton>
            <IconButton aria-label="facebook" href="https://www.facebook.com/Jhare-Express-LLC-104580475147556">
                <Facebook />
            </IconButton>
            <IconButton aria-label="twitter" href="https://twitter.com/jhareexpressllc">
                <Twitter />
            </IconButton>
        </Stack>
    )
}

export default SocialIcons