import { useRef } from 'react'
import SocialIcons from './SocialIcons';
import useElementSize from '../../../../hooks/useElementSize';

import {
    AppBar,
    Typography,
    Stack,
    Box,
    Container
} from '@mui/material'

const TobBar = () => {
    const ref = useRef();
    const { height } = useElementSize(ref);
    return (
        <>
            <AppBar ref={ref} sx={{borderTop: 0, borderLeft: 0, borderRight: 0}} variant="outlined" elevation={0}>
                <Container>
                    <Stack justifyContent="space-between" alignItems="center" sx={{flexDirection: { xs: 'column', rsm: 'row' }, pt: { xs: 1, rsm: 0 }}}>
                        <Typography>+1 (407) 922-2368</Typography>
                        <SocialIcons />
                    </Stack>
                </Container>
            </AppBar>
            <Box sx={{minHeight: `${height}px`}} />
        </>
    )
}

export default TobBar