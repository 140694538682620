import { MuiRouterLink } from "../../MuiRouterLink";
import SocialIcons from "../topbar/SocialIcons";
import siteLogo from '../../../../assets/images/jhare-logo.png'

import {
    Container,
    Stack,
    Typography,
    Avatar,
    useTheme,
    Paper
} from "@mui/material"

import BusinessIcon from '@mui/icons-material/Business';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

const Footer = () => {
    const theme = useTheme()
    return (
        <Paper elevation={0} sx={{ backgroundColor: "#fbf23f" }}>
            <Container>
                <Stack direction={{md: "row"}} gap={3} sx={{ py: 8 }}>
                    <Stack flex="1">
                        <Typography variant="h6" gutterBottom>OFFICE LOCATION</Typography>
                        <Stack direction="row" spacing={2} mb={theme.spacing(1)}>
                            <BusinessIcon color="action" />
                            <Typography>1100 US HWY 27, Suite F, 136151 Clermont, FL 34714</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2} mb={theme.spacing(1)}>
                            <LocalPhoneIcon color="action" />
                            <Typography>+1 (407) 922-2368</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2} mb={theme.spacing(1)}>
                            <EmailIcon color="action" />
                            <Typography>support@jhareexpress.com</Typography>
                        </Stack>
                    </Stack>
                    <Stack flex="1">
                        <Typography variant="h6" gutterBottom>USEFUL LINKS</Typography>
                        <MuiRouterLink to="/track" underline="hover" gutterBottom>Track</MuiRouterLink>
                        <MuiRouterLink to="/contact" underline="hover" gutterBottom>Contact us</MuiRouterLink>
                        <MuiRouterLink to="/about" underline="hover" gutterBottom>About us</MuiRouterLink>
                        <MuiRouterLink to="/privacy-policy" underline="hover" gutterBottom>Privacy policy</MuiRouterLink>
                    </Stack>
                    <Stack flex="1">
                        <Avatar variant="square" src={siteLogo} srcSet={siteLogo} sx={{ width: "160px", height: "auto", mb: theme.spacing(1) }} />
                        <SocialIcons sx={{ ml: `-${theme.spacing(1)}` }} />
                    </Stack>
                </Stack>
                <Typography align="center"  sx={{ pb: 3 }}>Copyright © 2023 Jhareexpress.com</Typography>
            </Container>
        </Paper>
    )
}

export default Footer