import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import siteLogo from '../../../assets/images/jhare-logo.png'
import useAuth from '../../../hooks/useAuth'
import useAxiosPublic from '../../../hooks/useAxiosPublic'

import {
  formErrorMsg
} from '../../../config/index'

import {
  Alert,
  MuiRouterLink,
  ReactHookFormMuiInput
} from '../../../common/index'

import {
  Stack,
  Avatar,
  Button,
  Container,
  IconButton,
  Typography,
  InputAdornment
} from "@mui/material";

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Login = () => {
  const axios                                            = useAxiosPublic();
  const navigate                                         = useNavigate();
  const location                                         = useLocation();
  const { setAuth, persist, setPersist }                 = useAuth();
  const [ showPassword, setShowPassword ]                = useState(false);
  const [ loading, setLoading ]                          = useState(false);
  const { handleSubmit, control, formState: { errors } } = useForm();
  const [ alert, setAlert ]                              = useState({open: false})
  const { open, message, severity }                      = alert

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist])

  useEffect(() => {
    if ( location?.state?.passwordReset ) {
      setAlert({
        open: true,
        message: 'Password reset successfully.',
        severity: 'success'
      })
    }
  }, [location?.state?.passwordReset])

  const onSubmit = async formData => {
    try {
      setLoading(true)
      const response = await axios.post(`/access-token`, {
        ...formData
      })
      setAuth(response.data);
      let from = location.state?.from?.pathname;
      if ( ! from ) {
        from = '/shipments'
      }
      navigate(from, { replace: true });
    } catch (err) {
      const data = err?.response?.data;
      const statusCode = data?.data?.status;
      let message
      if (!data) {
        message = 'No server response';
      } else if (400 === statusCode) {
        message = 'Missing username or password';
      } else if (401 === statusCode) {
        message = 'Unauthorized';
      } else if (403 === statusCode || 'incorrect_password' === data?.code || 'invalid_email' === data?.code) {
        message = 'Your email or password is incorrect';
      } else {
        message = 'Login failed';
      }
      setAlert({
        open: true,
        message,
        severity: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container maxWidth="xs" sx={{ py: "30px" }}>
      <Alert
        open={open}
        message={message}
        severity={severity}
        onClose={() => setAlert( {...alert, open: false} )}
      />
      <Stack alignItems="center" pb={4} spacing={2}>
        <Avatar variant="square" src={siteLogo} srcSet={siteLogo} sx={{ backgroundColor: "#fff", padding: "5px", width: "auto", height: "auto" }} />
        <Typography component="h1" variant="h5">Login</Typography>
        <Typography align="center" variant="body2">Don't have an account yet? <MuiRouterLink to="/signup">Sign up</MuiRouterLink></Typography>
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <ReactHookFormMuiInput
            control={control}
            id="username"
            name="username"
            label="Username"
            fullWidth
            autoComplete="username"
            rules={{required: formErrorMsg.required}}
            error={!!errors.username}
            helperText={errors.username && errors.username?.message}
          />
          
          <ReactHookFormMuiInput
            control={control}
            type={showPassword ? 'text' : 'password'}
            id="password"
            name="password"
            label="Password"
            fullWidth
            autoComplete="password"
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword( !showPassword )}
                  onMouseDown={() => setShowPassword( !showPassword )}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }}
            rules={{required: formErrorMsg.required}}
            error={!!errors.password}
            helperText={errors.password && errors.password?.message}
          />

          <div 
            className="persistCheck"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px'
            }}
          >
            <input
              type="checkbox"
              id="persist"
              onChange={() => setPersist(prev => !prev)}
              checked={persist}
              style={{
                height: '18px',
                width: '18px',
                accentColor: '#666666'
              }}
            />
            <label htmlFor="persist">Trust this device</label>
          </div>

          <Button
            type="submit"
            fullWidth
          >
            {loading ? 'Please wait...' : 'Login'}
          </Button>

          <Typography align="center" variant="body2"><MuiRouterLink to="/password-reset">Forgot your password?</MuiRouterLink></Typography>
        </Stack>
      </form>
    </Container>
  )
}

export default Login