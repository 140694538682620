import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import useTitle from '../../../../hooks/useTitle';

import {
  formErrorMsg,
  shipmentStatuses
} from '../../../../config/index'

import {
  Alert,
  ReactHookFormMuiInput,
  ReactHookFormMuiSelect,
  ReactHookFormMuiTextarea,
  ReactHookFormMuiAutocomplete,
  ReactHookFormMuiDateTimePicker
} from '../../../../common/index'

import {
  Stack,
  Button,
  Container
} from "@mui/material";

const NewTracking = () => {
  const { setTitle }                                     = useTitle()
  const axios                                            = useAxiosPrivate()
  let location                                           = useLocation()
  const [ loading, setLoading ]                          = useState(false)
  const [ shipments, setShipments ]                      = useState([])
  const { 
    handleSubmit, 
    control, 
    reset, 
    getValues, 
    formState: { 
      errors 
    } 
  }                                                      = useForm()
  const [ alert, setAlert ]                              = useState({open: false})
  const { open, message, severity }                      = alert

  useEffect(() => {
    setTitle('New Tracking')
  }, [setTitle])

  useEffect(() => {
    reset()
  }, [reset, location.key])

  const formatLabel = shipment => `${shipment.sender_tracking ? `${shipment.sender_tracking} - ` : ''}${shipment.description} (${shipmentStatuses.find((el) => el.value === shipment.status)?.label}) - ${shipment.ID}`

  useEffect(() => {
    const controller = new AbortController();
    const getShipments = async () => {
      try {
        const response = await axios.get('/shipments', {
          signal: controller.signal,
          params: {
            count: 0,
            exclude: 'tracking_number'
          }
        });
        const data = response.data.map((shipment) => ({
          value: shipment.ID,
          label: formatLabel( shipment )
        })) 
        setShipments( data );
      } catch (err) {
        if (err.code !== 'ERR_CANCELED') {
          //console.log(err?.response.data, 'Error');
        }
      }
    }
    getShipments();
    return () => { 
      controller.abort();
    }
  }, [axios, location.key])

  let controller;
  const getShipments = async (e, inputValue, reason) => {
    if (reason === 'reset' || !inputValue || inputValue.length < 2) {
      return;
    }
    if (controller) {
      controller.abort()
    }
    controller = new AbortController();
    try {
      const response = await axios.get('/shipments', {
        signal: controller.signal,
        params: {
          search: inputValue,
          count: 0,
          exclude: 'tracking_number',
          per_page: -1
        }
      })
      const data = response.data.map((shipment) => ({
        value: shipment.ID,
        label: formatLabel( shipment )
      }))
      const currentValues = getValues('shipments');
      const filtered      = data.filter(( shipment ) => ! currentValues.some( (currentValue) => currentValue.value === shipment.value ) )
      setShipments( [...filtered, ...currentValues] )
    } catch (err) {
      if (err.code !== 'ERR_CANCELED') {
        //console.log(err?.response?.data, 'Error');
      }
    }
  }

  const onSubmit = async formData => {
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        if ( key === 'shipments' ) {
          formData[key] = formData[key].map((shipment) => shipment.value)
        }
      }
    }
    try {
      setLoading(true)
      await axios.post(`/tracking`, {
        ...formData
      })
      setAlert({
        open: true,
        message: 'Saved successfully.',
        severity: 'success'
      })
    } catch (err) {
      setAlert({
        open: true,
        message: err.response.data.message,
        severity: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container disableGutters maxWidth="xs">
      <Alert
        privateLayout
        open={open}
        message={message}
        severity={severity}
        onClose={() => setAlert( {...alert, open: false} )}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <ReactHookFormMuiInput
            control={control}
            id="tracking_number"
            name="tracking_number"
            label="Tracking number"
            fullWidth
            autoComplete="tracking_number"
            autoFocus
            rules={{required: formErrorMsg.required}}
            error={!!errors.tracking_number}
            helperText={errors.tracking_number && errors.tracking_number?.message}
          />

          <ReactHookFormMuiSelect
            control={control}
            id="status"
            name="status"
            label="Shipment status"
            fullWidth
            autoComplete="status"
            rules={{required: formErrorMsg.required}}
            error={!!errors.status}
            helperText={errors.status && errors.status?.message}
            options={[
              {
                  value: 'shipped', 
                  label: 'Shipped'
              }, 
              {
                  value: 'delivered', 
                  label: 'Delivered'
              }
            ]}
            defaultValue="shipped"
          />

          <ReactHookFormMuiDateTimePicker
            control={control}
            name="date"
            label="Date"
            rules={{required: formErrorMsg.required}}
            error={!!errors.date}
            helperText={errors.date && errors.date?.message}
          />

          <ReactHookFormMuiAutocomplete
            control={control}
            multiple
            id="shipments"
            name="shipments"
            label="Shipments"
            rules={{required: false}}
            error={!!errors.shipments}
            helperText={errors.shipments && errors.shipments?.message}
            options={shipments}
            onInputChange={getShipments}
          />

          <ReactHookFormMuiTextarea
            control={control}
            id="tracking_info"
            name="tracking_info"
            label="Tracking info"
            fullWidth
            autoComplete="tracking_info"
            rules={{required: false}}
            error={!!errors.tracking_info}
            helperText={errors.tracking_info && errors.tracking_info?.message}
          />

          <Button
            type="submit"
            fullWidth
          >
            {loading ? 'Please wait...' : 'Save'}
          </Button>
        </Stack>
      </form>
    </Container>
  )
}

export default NewTracking