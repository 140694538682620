import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import { siteURL } from '../../../../config/index'
import useTitle from '../../../../hooks/useTitle'
import useSplitPathname from '../../../../hooks/useSplitPathname'

import {
  ROLES,
  formErrorMsg
} from '../../../../config/index'

import {
  Alert,
  Loading,
  ReactHookFormMuiInput,
  ReactHookFormMuiSelect
} from '../../../../common/index'

import {
  Button,
  Stack,
  Container,
  InputAdornment,
  IconButton
} from "@mui/material";

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const userRoles = () => {
  const roles = []
  for (const key in ROLES) {
    if (ROLES.hasOwnProperty(key)) {
      roles.push({
        value: ROLES[key].key, 
        label: ROLES[key].name
      });
    }
  }
  return roles;
}

const EditUsers = () => {
  const { setTitle } = useTitle()
  const axios = useAxiosPrivate()
  const path = useSplitPathname()
  const userID = path[1]
  const [ loading, setLoading ] = useState(true)
  const [ btnLoading, setBtnLoading ] = useState(false)
  const [ showPassword, setShowPassword ] = useState( false )
  const { handleSubmit, reset, control, formState: { errors } } = useForm()
  const [ alert, setAlert ] = useState({open: false})
  const { open, message, severity } = alert

  useEffect(() => {
    setTitle('Edit User')
  }, [setTitle])
  
  useEffect(() => {
    const controller = new AbortController();
    const getData = async () => {
      try {
        setLoading(true)
        const response = await axios.get(`${siteURL}/wp-json/wp/v2/users/${userID}`, {
          signal: controller.signal,
        })
        reset({
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          username: response.data.username,
          email: response.data.email,
          role: response.data.roles[0],
        })
        setLoading(false)
      } catch (err) {
        if (err.code !== 'ERR_CANCELED') {
          //console.log(err.response.data, 'Error');
        }
      }
    }
    getData()
    return () => {
      controller.abort();
    }
  }, [axios, reset, userID])

  const onSubmit = async formData => {
    // Prepare data
    const newData = {}
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        const element = formData[key];
        if (element) {
          if ('role' === key) {
            newData['roles'] = [element]
          } else {
            newData[key] = element
          }
        }
      }
    }
    newData.name = `${newData.first_name} ${newData.last_name}`
    try {
      setBtnLoading(true)
      await axios.post(`${siteURL}/wp-json/wp/v2/users/${userID}`, {
        ...newData
      })
      setAlert({
        open: true,
        message: 'Updated successfully.',
        severity: 'success'
      })
    } catch (err) {
      setAlert({
        open: true,
        message: err.response.data.message,
        severity: 'error'
      })
    } finally {
      setBtnLoading(false)
    }
  }
  
  return (
    <Container disableGutters maxWidth="xs">
      <Alert
        privateLayout
        open={open}
        message={message}
        severity={severity}
        onClose={() => setAlert( {...alert, open: false} )}
      />
      {loading ? <Loading privateLayout />
      : <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
        <ReactHookFormMuiInput
            control={control}
            id="first_name"
            name="first_name"
            label="First Name"
            fullWidth
            autoComplete="first_name"
            autoFocus
            rules={{required: formErrorMsg.required}}
            error={!!errors.first_name}
            helperText={errors.first_name && errors.first_name?.message}
          />
        
          <ReactHookFormMuiInput
            control={control}
            id="last_name"
            name="last_name"
            label="Last Name"
            fullWidth
            autoComplete="last_name"
            rules={{required: formErrorMsg.required}}
            error={!!errors.last_name}
            helperText={errors.last_name && errors.last_name?.message}
          />
        
          <ReactHookFormMuiInput
            control={control}
            id="username"
            name="username"
            label="Username"
            fullWidth
            disabled
            autoComplete="username"
            rules={{required: formErrorMsg.required, pattern: { value: /^[a-zA-Z0-9-_]{3,30}$/, message: formErrorMsg.invalidUsername}}}
            error={!!errors.username}
            helperText={errors.username && errors.username?.message}
          />

          <ReactHookFormMuiInput
            control={control}
            id="email"
            name="email"
            label="Email Address"
            fullWidth
            autoComplete="email"
            rules={{required: formErrorMsg.required, pattern: { value: /^\S+@\S+$/i, message: formErrorMsg.invalidEmail}}}
            error={!!errors.email}
            helperText={errors.email && errors.email?.message}
          />

          <ReactHookFormMuiSelect
            control={control}
            id="role"
            name="role"
            label="Role"
            fullWidth
            autoComplete="role"
            rules={{required: formErrorMsg.required}}
            error={!!errors.role}
            helperText={errors.role && errors.role?.message}
            options={userRoles()}
          />
          
          <ReactHookFormMuiInput
            control={control}
            type={showPassword ? 'text' : 'password'}
            id="password"
            name="password"
            label="Password"
            fullWidth
            autoComplete="password"
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword( !showPassword )}
                  onMouseDown={() => setShowPassword( !showPassword )}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }}
            rules={{required: false, min: { value: 5, message: formErrorMsg.minPass}}}
            error={!!errors.password}
            helperText={errors.password && errors.password?.message}
          />

          <Button
            type="submit"
            fullWidth
          >
            {btnLoading ? 'Please wait...' : 'Update user'}
          </Button>
        </Stack>
      </form>}
    </Container>
  )
}

export default EditUsers