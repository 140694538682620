import { useMemo, useCallback, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useTitle from '../../../../hooks/useTitle';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { siteURL } from '../../../../config/index';

import {
  Alert,
  Table,
  Loading
} from '../../../../common/index'

import {
  Container
} from "@mui/material";

const AllUsers = () => {
  const { setTitle }                = useTitle()
  const axios                       = useAxiosPrivate();
  const navigate                    = useNavigate();
  const [ loading, setLoading ]     = useState(true);
  const [users, setUsers]           = useState([]);
  const [ alert, setAlert ]         = useState({open: false})
  const { open, message, severity } = alert

  useEffect(() => {
    setTitle('All Users')
  }, [setTitle])

  useEffect(() => {
    const controller = new AbortController();
    const getUsers = async () => {
      try {
        setLoading( true )
        const response = await axios.get(`${siteURL}/wp-json/wp/v2/users`, {
          signal: controller.signal,
          params: {
            per_page: 40,
            order: 'desc',
            orderby: 'id'
          }
        });
        const users = response.data.map((data) => {
          data.roles = data?.roles?.map(
            (role) => role.charAt(0).toUpperCase() + role.slice(1)
          ).join(', ')
          return data;
        })
        setUsers( users );
        setLoading( false );
      } catch (err) {
        if (err.code !== 'ERR_CANCELED') {
          //console.log(err?.response?.data, 'Error');
        }
      }
    }
    getUsers();
    return () => { 
      controller.abort();
    }
  }, [axios])
  
  const deleteUser = useCallback(
    (id) => async () => {
      if ( ! window.confirm('Are you sure you want to delete this user?') ) {
        return false;
      }
      try {
        await axios.delete(`${siteURL}/wp-json/wp/v2/users/${id}`, {
          params: {
            force: true,
            reassign: 1
          }
        });
        setUsers( users.filter( (user) => user.id !== id) );
        setAlert({
          open: true,
          message: 'Deleted successfully.',
          severity: 'success'
        })
      } catch (err) {
        setAlert({
          open: true,
          message: err?.response?.data?.message || 'No server response',
          severity: 'error'
        })
      }
    },
    [axios, users]
  );
  
  const editUser = useCallback(
    (id) => () => {
      navigate(`/users/${id}`)
    },
    [navigate]
  );

  const columns = useMemo(
    () => [
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions', 
        width: 80,
        getActions: (params) => 
          [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit user"
              onClick={editUser(params.id)}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={deleteUser(params.id)}
            />
          ]
      },
      { field: 'first_name', headerName: 'First name', width: 130 },
      { field: 'last_name', headerName: 'Last name', width: 130 },
      { field: 'email', headerName: 'Email', type: 'string', width: 250 },
      { field: 'roles', headerName: 'Roles', type: 'string', width: 130, }
    ],
    [deleteUser, editUser],
  );

  return (
    <Container disableGutters>
      <Alert
        privateLayout
        open={open}
        message={message}
        severity={severity}
        onClose={() => setAlert( {...alert, open: false} )}
      />
      {loading && <Loading privateLayout />}
      {!loading && <form>
        <Table
          height='calc(100vh - 112px)'
          rows={users}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          checkboxSelection
        />
      </form>}
    </Container>
  )
}

export default AllUsers