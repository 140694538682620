import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import useTitle from '../../../../hooks/useTitle';

import {
  shipmentStatuses,
  formErrorMsg
} from '../../../../config/index'

import {
  Alert,
  Loading,
  FileUpload,
  ReactHookFormMuiInput,
  ReactHookFormMuiSelect,
  ReactHookFormMuiTextarea,
  ReactHookFormMuiAutocomplete,
} from '../../../../common/index'

import {
  Button,
  Stack,
  Container,
} from "@mui/material";

const EditShipment = () => {
  const axios                                            = useAxiosPrivate();
  let { id: shipmentID }                                 = useParams();
  const { setTitle }                                     = useTitle();
  const [ users, setUsers ]                              = useState([]);
  const [ loading, setLoading ]                          = useState(true);
  const [ btnLoading, setBtnLoading ]                    = useState(false);
  const [ shipmentData, setShipmentData ]                = useState({});
  const { handleSubmit, control, getValues, formState: { errors } } = useForm();
  const [ alert, setAlert ]                              = useState({open: false});
  const { open, message, severity }                      = alert;
  
  useEffect(() => {
    setTitle('Edit Shipment')
  }, [setTitle])

  useEffect( () => {
    const controller = new AbortController();
    const getData = async () => {
      try {
        setLoading(true)
        const response = await axios.get(`/shipments/${shipmentID}`, {
          signal: controller.signal
        });
        response.data.recipient && setUsers( [response.data.recipient] )
        setShipmentData( response.data )
        setLoading(false)
      } catch (err) {
        if (err.code !== 'ERR_CANCELED') {
          //console.log(err.response.data, 'Error');
        }
      }
    }
    getData()
    return () => {
      controller.abort();
    }
  }, [axios, shipmentID])

  let controller;
  const getUsers = async (e, inputValue, reason) => {
    if (reason === 'reset' || !inputValue || inputValue.length < 3) {
      return;
    }
    if (controller) {
      controller.abort()
    }
    controller = new AbortController();
    try {
      const response = await axios.get('/search-users', {
        signal: controller.signal,
        params: {
          search: inputValue
        }
      })
      const currentValue = getValues('recipient_id');
      const filtered = currentValue ? response.data.filter((user) => user.value !== currentValue.value) : response.data
      setUsers(
        currentValue ? [...filtered, currentValue ] : filtered
      )
    } catch (err) {
      if (err.code !== 'ERR_CANCELED') {
        //console.log(err?.response?.data, 'Error');
      }
    }
  }

  const onSubmit = async formData => {
    for (const key in formData) {
      if ( formData.hasOwnProperty( key ) ) {
        if ( ! formData[key] ) {
          // Remove empty data
          delete formData[key];
        } else if ( key === 'recipient_id' ){
          formData[key] = formData[key].value
        }
      }
    }
    try {
      setBtnLoading(true)
      await axios.post(
        `/shipments/${shipmentID}`, 
        {
          ...formData
        }, 
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      setAlert({
        open: true,
        message: 'Updated successfully.',
        severity: 'success'
      })
    } catch (err) {
      setAlert({
        open: true,
        message: err?.response?.data?.message || 'No server response',
        severity: 'error'
      })
    } finally {
      setBtnLoading(false)
    }
  }

  return (
    <Container disableGutters maxWidth="xs">
      <Alert
        privateLayout
        open={open}
        message={message}
        severity={severity}
        onClose={() => setAlert( {...alert, open: false} )}
      />
      {loading && <Loading privateLayout />}
      {!loading && <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>

          <ReactHookFormMuiInput
            control={control}
            id="description"
            name="description"
            label="Short description"
            fullWidth
            autoComplete="description"
            autoFocus
            rules={{required: formErrorMsg.required}}
            error={!!errors.description}
            helperText={errors.description && errors.description?.message}
            defaultValue={shipmentData.description}
          />

          <ReactHookFormMuiInput
            control={control}
            id="sender_tracking"
            name="sender_tracking"
            label="Sender tracking number"
            fullWidth
            autoComplete="sender_tracking"
            rules={{required: false}}
            error={!!errors.sender_tracking}
            helperText={errors.sender_tracking && errors.sender_tracking?.message}
            defaultValue={shipmentData.sender_tracking}
          />

          <ReactHookFormMuiSelect
            control={control}
            id="status"
            name="status"
            label="Shipment status"
            fullWidth
            autoComplete="status"
            rules={{required: formErrorMsg.required}}
            error={!!errors.status}
            helperText={errors.status && errors.status?.message}
            options={shipmentStatuses}
            defaultValue={shipmentData.status}
          />
          
          <ReactHookFormMuiAutocomplete
            control={control}
            label="Recipient"
            options={users}
            defaultValue={shipmentData.recipient}
            onInputChange={getUsers}
            id="recipient_id"
            name="recipient_id"
            rules={{required: false}}
            error={!!errors.note}
            helperText={errors.note && errors.note?.message}
          />

          <FileUpload
            control={control}
            name="attachment"
            rules={{required: false}}
            error={!!errors.attachment}
            helperText={errors.attachment && errors.attachment?.message}
            maxFileSize={15000000}
            acceptedFiles={['image/*']}
            dropzoneText={"Upload the package image(s)"}
            initialFiles={shipmentData.attachment_urls || []}
          />

          <ReactHookFormMuiTextarea
            control={control}
            id="note"
            name="note"
            label="Note"
            fullWidth
            autoComplete="note"
            rules={{required: false}}
            error={!!errors.note}
            helperText={errors.note && errors.note?.message}
            defaultValue={shipmentData.note}
          />

          <Button
            type="submit"
            fullWidth
          >
            {btnLoading ? 'Please wait...' : 'Update'}
          </Button>
        </Stack>
      </form>}
    </Container>
  )
}

export default EditShipment