import {
  Typography,
  Container,
} from "@mui/material";

const PrivacyPolicy = () => {

  return (
    <Container sx={{ margin: "30px auto"}}>
        <Typography component="h1" variant="h4" align="center" mb={4}>Privacy Policy</Typography>
        <Typography mb={2}>JHARE EXPRESS, LLC LIMITATION OF LIABILITY + TERMS & CONDITIONS</Typography>
        <Typography>By shipping with JHARE EXPRESS, LLC, you agree that you have read and agreed to the terms and conditions included in this document.</Typography>

        <Typography mb={2}>As a legally operated business in the state of Florida, JHARE EXPRESS, LLC, is required to comply with state and federal laws. At JHARE EXPRESS, LLC, we will make every effort to respect your privacy while ensuring that our actions fully comply with applicable state and federal laws.</Typography>

        <Typography mb={2}>You understand and agree that JHARE EXPRESS, LLC in its sole discretion may, open inspect, refuse to ship, or return to sender packages that are deemed suspicious or where there is reasonable cause to suspect that the packages or the circumstances under which they were obtained or shipped to us violate any state, federal or international laws.</Typography>

        <Typography mb={2}>By shipping with JHARE EXPRESS, LLC, you assume all responsibility to comply with all laws and regulations of the United States and international countries, including but not limited to the U.S. Export Administration Regulations (“EAR”), International Traffic in Arms Regulations, and economic sanctions programs maintained by the U.S. Treasury Office of Foreign Assets Control (“OFAC”), and those of any other country to which your package is being shipped.</Typography>

        <Typography mb={2}>You represent and warrant that you will not export or import any item or product from or to any country sanctioned or embargoed by the United States such as Syria, Cuba, Iran, North Korea. You also represent to JHARE EXPRESS, LLC, that you are not restricted from performing such services by being designated a Specially Designated National by OFAC.</Typography>

        <Typography mb={2}>DISCLAIMER OF WARRANTY</Typography>

        <Typography mb={2}>JHARE EXPRESS, LLC, PACKAGING, AND SHIPPING SERVICES ARE PROVIDED “AS IS” AND JHARE EXPRESS, LLC, DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE CONCERNING THE USE OF OUR SERVICES.</Typography>

        <Typography mb={2}>AUTHORIZATION TO INSPECT</Typography>

        <Typography mb={2}>While JHARE EXPRESS, LLC, will make every effort to secure and protect your property during packaging and transportation, IN NO EVENT SHALL OUR LIABILITY FOR DAMAGES OR LOSS OF PACKAGES EXCEED $250, HOWEVER, CAUSED. CUSTOMERS ARE ADVISED TO OBTAIN INSURANCE FOR THEIR PACKAGE CONTENTS.</Typography>

        <Typography mb={2}>FORM REQUIRED FOR THIRD PARTY SHIPPING</Typography>

        <Typography mb={2}>This form signed, dated, and which includes the shipper’s return address must accompany every package forwarded to JHARE EXPRESS, LLC. Packages received that do not include this form, or includes an incomplete or unsigned form, will be held for a period of 30-days, after which said packages will be returned at the sender’s expense. All third-party shippers must contact JHARE EXPRESS LLC via email at support@jhareexpress.com before proceeding to forward packages to our address.</Typography>

        <Typography mb={2}>FINAL AGREEMENT</Typography>

        <Typography mb={2}>These terms of service and limitation of liability constitute the parties’ complete agreement concerning the subject matter supersede any and all prior or contemporaneous discussions, statements, understandings, representations, or agreements, written or oral, regarding the subject matter. Without its written consent, no amendment to or modification of this agreement will be binding on JHARE EXPRESS, LLC. Any provision of this agreement that is prohibited or unenforceable in any jurisdiction shall, as to such jurisdiction, be ineffective to the extent of such prohibition or unenforceability without invalidating the remaining provisions hereof.</Typography>
    </Container>
  );
}

export default PrivacyPolicy