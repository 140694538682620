export const siteName = 'Jhare Express'

export const siteURL = 'https://api.jhareexpress.com'
export const apiBase = `${siteURL}/wp-json/jhare/v1`

//export const siteURL = 'http://localhost/jhare'
//export const apiBase = `${siteURL}/wp-json/jhare/v1`

export const formErrorMsg = {
    required: 'This field is required',
    invalidEmail: 'Enter a valid email',
    passMatch: 'Passwords must match',
    minPass: 'You must enter at least 5 characters',
    invalidUsername: 'Your username must be 3 to 30 characters. Only letters, numbers, underscores, and hyphens are allowed'
}

export const ROLES = {
  user: {
    key: 'subscriber',
    name: 'Subscriber',
  },
  admin: {
    key: 'administrator',
    name: 'Administrator',
  }
}

export const notifications = [
    {
        value: 'newDeals', 
        label: 'New deals'
    }, 
    {
        value: 'newDelivery', 
        label: 'New delivery'
    }, 
    {
        value: 'cancelledDelivery', 
        label: 'Cancelled delivery'
    }
]

export const shipmentStatuses = [
    {
        value: 'received', 
        label: 'Received'
    }, 
    {
        value: 'shipped', 
        label: 'Shipped'
    }, 
    {
        value: 'delivered', 
        label: 'Delivered'
    }, 
    {
        value: 'on-hold', 
        label: 'On hold'
    }, 
    {
        value: 'rts',
        label: 'Return to sender'
    }
]

export const states = [
    {
      value: "FC",
      label: "Abuja"
    },
    {
      value: "AB",
      label: "Abia"
    },
    {
      value: "AD",
      label: "Adamawa"
    },
    {
      value: "AK",
      label: "AkwaIbom"
    },
    {
      value: "AN",
      label: "Anambra"
    },
    {
      value: "BA",
      label: "Bauchi"
    },
    {
      value: "BY",
      label: "Bayelsa"
    },
    {
      value: "BE",
      label: "Benue"
    },
    {
      value: "BO",
      label: "Borno"
    },
    {
      value: "CR",
      label: "CrossRiver"
    },
    {
      value: "DE",
      label: "Delta"
    },
    {
      value: "EB",
      label: "Ebonyi"
    },
    {
      value: "ED",
      label: "Edo"
    },
    {
      value: "EK",
      label: "Ekiti"
    },
    {
      value: "EN",
      label: "Enugu"
    },
    {
      value: "GO",
      label: "Gombe"
    },
    {
      value: "IM",
      label: "Imo"
    },
    {
      value: "JI",
      label: "Jigawa"
    },
    {
      value: "KD",
      label: "Kaduna"
    },
    {
      value: "KN",
      label: "Kano"
    },
    {
      value: "KT",
      label: "Katsina"
    },
    {
      value: "KE",
      label: "Kebbi"
    },
    {
      value: "KO",
      label: "Kogi"
    },
    {
      value: "KW",
      label: "Kwara"
    },
    {
      value: "LA",
      label: "Lagos"
    },
    {
      value: "NA",
      label: "Nassarawa"
    },
    {
      value: "NI",
      label: "Niger"
    },
    {
      value: "OG",
      label: "Ogun"
    },
    {
      value: "ON",
      label: "Ondo"
    },
    {
      value: "OS",
      label: "Osun"
    },
    {
      value: "OY",
      label: "Oyo"
    },
    {
      value: "PL",
      label: "Plateau"
    },
    {
      value: "RI",
      label: "Rivers"
    },
    {
      value: "SO",
      label: "Sokoto"
    },
    {
      value: "TA",
      label: "Taraba"
    },
    {
      value: "YO",
      label: "Yobe"
    },
    {
      value: "ZA",
      label: "Zamfara"
    }
  ]