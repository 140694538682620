import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import useAxiosPublic from '../../../hooks/useAxiosPublic';

import {
  states,
  formErrorMsg
} from '../../../config/index'

import {
  Alert,
  ReactHookFormMuiInput,
  ReactHookFormMuiSelect,
  ReactHookFormMuiDateTimePicker
} from '../../../common/index'

import {
  Button,
  Stack,
  Container,
  Typography,
  InputAdornment
} from "@mui/material"

const Quote = () => {

  const theme = useTheme()
  const axios = useAxiosPublic()
  const { handleSubmit, control, formState: { errors } } = useForm()
  const [ loading, setLoading ] = useState(false)
  const [ alert, setAlert ] = useState({
    open: false
  })
  const { open, message, severity } = alert

  const onSubmit = async formData => {
    console.log(formData, 'formData')
    try {
      setLoading(true)
      const response = await axios.post('/quote', {
        ...formData
      })
      console.log(response.data, 'formData')
      setAlert({
        open: true,
        message: 'Message sent',
        severity: 'success'
      })
    } catch (err) {
      console.log(err.response.data.message, 'request error')
      setAlert({
        open: true,
        message: err.response.data.message,
        severity: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container maxWidth="xs" sx={{ py: "30px"}}>
      <Alert
        open={open}
        message={message}
        severity={severity}
        onClose={() => setAlert( {...alert, open: false} )}
      />
      <Typography variant="h4" align="center" mb={4}>Request a quote</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Typography fontWeight="medium">Sender info</Typography>

          <Stack gap={theme.spacing(2)} flexDirection="row">
            <ReactHookFormMuiInput
              control={control}
              id="fname"
              name="fname"
              label="First Name"
              fullWidth
              autoComplete="fname"
              autoFocus
              rules={{required: formErrorMsg.required}}
              error={!!errors.fname}
              helperText={errors.fname && errors.fname?.message}
            />
          
            <ReactHookFormMuiInput
              control={control}
              id="lname"
              name="lname"
              label="Last Name"
              fullWidth
              autoComplete="lname"
              rules={{required: formErrorMsg.required}}
              error={!!errors.lname}
              helperText={errors.lname && errors.lname?.message}
            />
          </Stack>

          <ReactHookFormMuiInput
            control={control}
            id="email"
            name="email"
            label="Email Address"
            fullWidth
            autoComplete="email"
            rules={{required: formErrorMsg.required, pattern: { value: /^\S+@\S+$/i, message: formErrorMsg.invalidEmail}}}
            error={!!errors.email}
            helperText={errors.email && errors.email?.message}
          />
                    
          <ReactHookFormMuiInput
            control={control}
            id="phoneNumber"
            name="phoneNumber"
            label="Phone number"
            fullWidth
            autoComplete="phoneNumber"
            rules={{required: true}}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber && errors.phoneNumber?.message}
          />

          <Typography fontWeight="medium">Receiver info</Typography>

          <Stack gap={theme.spacing(2)} flexDirection="row">
            <ReactHookFormMuiInput
              control={control}
              id="receiverFname"
              name="receiverFname"
              label="First Name"
              fullWidth
              autoComplete="receiverFname"
              rules={{required: formErrorMsg.required}}
              error={!!errors.receiverFname}
              helperText={errors.receiverFname && errors.receiverFname?.message}
            />
          
            <ReactHookFormMuiInput
              control={control}
              id="receiverLname"
              name="receiverLname"
              label="Last Name"
              fullWidth
              autoComplete="receiverLname"
              rules={{required: formErrorMsg.required}}
              error={!!errors.receiverLname}
              helperText={errors.receiverLname && errors.receiverLname?.message}
            />
          </Stack>
                      
          <ReactHookFormMuiInput
            control={control}
            id="receiverPhoneNumber"
            name="receiverPhoneNumber"
            label="Phone number"
            fullWidth
            autoComplete="receiverPhoneNumber"
            rules={{required: formErrorMsg.required}}
            error={!!errors.receiverPhoneNumber}
            helperText={errors.receiverPhoneNumber && errors.receiverPhoneNumber?.message}
          />

          <Typography fontWeight="medium">Shipping info</Typography>

          <ReactHookFormMuiDateTimePicker
            control={control}
            name="dateTime"
            label="Shipping date"
            rules={{required: formErrorMsg.required}}
            error={!!errors.dateTime}
            helperText={errors.dateTime && errors.dateTime?.message}
          />
                    
          <ReactHookFormMuiInput
            type="number"
            control={control}
            id="totalWeight"
            name="totalWeight"
            label="Total weight (Kgs)"
            fullWidth
            autoComplete="totalWeight"
            rules={{required: false}}
            error={!!errors.totalWeight}
            helperText={errors.totalWeight && errors.totalWeight?.message}
            InputProps={{
              inputProps: { min: 0 }
            }}
          />
          
          <Stack gap={theme.spacing(2)} flexDirection="row" alignItems="center" mb={3}>        
            <ReactHookFormMuiInput
              type="number"
              control={control}
              id="parcelLength"
              name="parcelLength"
              label="Length"
              fullWidth
              autoComplete="parcelLength"
              rules={{required: false}}
              error={!!errors.parcelLength}
              helperText={errors.parcelLength && errors.parcelLength?.message}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
                      
            <ReactHookFormMuiInput
              type="number"
              control={control}
              id="parcelWidth"
              name="parcelWidth"
              label="Width"
              fullWidth
              autoComplete="parcelWidth"
              rules={{required: false}}
              error={!!errors.parcelWidth}
              helperText={errors.parcelWidth && errors.parcelWidth?.message}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
                      
            <ReactHookFormMuiInput
              type="number"
              control={control}
              id="parcelHeight"
              name="parcelHeight"
              label="Height"
              fullWidth
              autoComplete="parcelHeight"
              rules={{required: false}}
              error={!!errors.parcelHeight}
              helperText={errors.parcelHeight && errors.parcelHeight?.message}
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </Stack>
                    
          <ReactHookFormMuiInput
            type="number"
            control={control}
            id="parcelValue"
            name="parcelValue"
            label="Value of Cargo or Parcel"
            fullWidth
            autoComplete="parcelValue"
            rules={{required: false}}
            error={!!errors.parcelValue}
            helperText={errors.parcelValue && errors.parcelValue?.message}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              inputProps: { min: 0 }
            }}
          />

          <ReactHookFormMuiSelect
            control={control}
            id="additionalCover"
            name="additionalCover"
            label="Additional Cover"
            fullWidth
            autoComplete="additionalCover"
            rules={{required: false}}
            error={!!errors.additionalCover}
            helperText={errors.additionalCover && errors.additionalCover?.message}
            options={[
              {
                  value: 'yes',
                  label: 'Yes'
              }, 
              {
                  value: 'no', 
                  label: 'No'
              }
            ]}
          />

          <ReactHookFormMuiInput
            control={control}
            id="description"
            name="description"
            label="Item description"
            fullWidth
            autoComplete="description"
            multiline
            rows={3}
            rules={{required: formErrorMsg.required}}
            error={!!errors.description}
            helperText={errors.description && errors.description?.message}
          />

          <Typography fontWeight="medium" mb={2}>Pickup address</Typography>

          <ReactHookFormMuiInput
            control={control}
            id="pickupAddress"
            name="pickupAddress"
            label="Address"
            autoComplete="address"
            fullWidth
            rules={{required: formErrorMsg.required}}
            error={!!errors.pickupAddress}
            helperText={errors.pickupAddress && errors.pickupAddress?.message}
          />

          <Stack gap={theme.spacing(2)} flexDirection="row" alignItems="center">
            <ReactHookFormMuiInput
              control={control}
              id="pickupCity"
              name="pickupCity"
              label="City"
              fullWidth
              autoComplete="city"
              rules={{required: formErrorMsg.required}}
              error={!!errors.pickupCity}
              helperText={errors.pickupCity && errors.pickupCity?.message}
            />

            <ReactHookFormMuiSelect
              control={control}
              id="pickupState"
              name="pickupState"
              label="State"
              fullWidth
              autoComplete="state"
              rules={{required: formErrorMsg.required}}
              error={!!errors.pickupState}
              helperText={errors.pickupState && errors.pickupState?.message}
              options={states}
              defaultValue=""
            />
          </Stack>

          <Typography fontWeight="medium">Delivery address</Typography>
          
          <ReactHookFormMuiInput
            control={control}
            id="deliveryAddress"
            name="deliveryAddress"
            label="Address"
            autoComplete="address"
            fullWidth
            rules={{required: formErrorMsg.required}}
            error={!!errors.deliveryAddress}
            helperText={errors.deliveryAddress && errors.deliveryAddress?.message}
          />

          <Stack gap={theme.spacing(2)} flexDirection="row" alignItems="center" mb={3}>
            <ReactHookFormMuiInput
              control={control}
              id="deliveryCity"
              name="deliveryCity"
              label="City"
              fullWidth
              autoComplete="city"
              rules={{required: formErrorMsg.required}}
              error={!!errors.deliveryCity}
              helperText={errors.deliveryCity && errors.deliveryCity?.message}
            />

            <ReactHookFormMuiSelect
              control={control}
              id="deliveryState"
              name="deliveryState"
              label="State"
              fullWidth
              autoComplete="state"
              rules={{required: formErrorMsg.required}}
              error={!!errors.deliveryState}
              helperText={errors.deliveryState && errors.deliveryState?.message}
              options={states}
              defaultValue=""
            />
          </Stack>

          <Button
            type="submit"
            fullWidth
          >
            {loading ? 'Please wait...' : 'Send'}
          </Button>
        </Stack>
      </form>
    </Container>
  )
}

export default Quote