import moment from 'moment';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useController } from "react-hook-form";

export const ReactHookFormMuiDateTimePicker = ( props ) => {
    const {
        control,
        rules,
        label,
        error,
        helperText,
        defaultValue,
        name: inputName,
        ...rest
    } = props

    const {
        field: { onChange, value, name, ref }
    } = useController({
        name: inputName,
        control,
        rules,
        defaultValue: defaultValue || moment().toISOString()
    })

    const handleChange = (newValue) => {
        onChange(newValue.toISOString());
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
                name={name}
                inputRef={ref}
                label={label}
                value={value}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} />}
                {...rest}
            />
            {error && <FormHelperText sx={{marginTop: '3px'}} error={error}>{helperText}</FormHelperText>}
        </LocalizationProvider>
    );
}