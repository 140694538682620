import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import siteLogo from '../../../../assets/images/jhare-logo.png'

import {
  MuiRouterLink
} from '../../MuiRouterLink'

import {
  Box,
  Avatar,
  Stack,
  Button,
  Container,
  AppBar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';

const drawerWidth = 240;
const navItems = [
  {name: 'Track', to: '/track'},
  {name: 'Login', to: '/login'},
  {name: 'Signup', to: '/signup'}
];
const navQuoteItem = {name: 'Get Quote', to: '/quote'}

function DrawerAppBar() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  }

  return (
    <Container>
      <AppBar position="relative" component="nav" color="transparent" elevation={0}>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" p="5px 0">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <MuiRouterLink to="/">
              <Avatar variant="square" src={siteLogo} srcSet={siteLogo} sx={{ width: "160px", height: "auto" }} />
            </MuiRouterLink>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center" gap="20px" sx={{ display: { xs: 'none', sm: 'flex' } }}>
            {navItems.map((item) => (
              <MuiRouterLink key={item.name} to={item.to} underline="hover">
                {item.name}
              </MuiRouterLink>
            ))}
            <Button component={Link} to={navQuoteItem.to}>{navQuoteItem.name}</Button>
          </Box>
        </Stack>
      </AppBar>
      <Box component="nav">
        <Drawer
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <MuiRouterLink to="/">
              <Avatar variant="square" src={siteLogo} srcSet={siteLogo} sx={{m: "16px auto", width: "160px", height: "auto"}} />
            </MuiRouterLink>
            <Divider />
            <List>
              {navItems.map((item) => (
                <ListItem key={item.name} disablePadding>
                  <ListItemButton component={Link} to={item.to} sx={{ textAlign: 'center' }}>
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Button component={Link} to={navQuoteItem.to} sx={{my:"8px"}}>{navQuoteItem.name}</Button>
          </Box>
        </Drawer>
      </Box>
    </Container>
  );
}

export default DrawerAppBar;
