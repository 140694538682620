import { useController } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from "@mui/material";

export const ReactHookFormMuiSelect = ( props ) => {
    const {
        fullWidth,
        control,
        rules,
        label,
        labelId,
        error,
        helperText,
        options,
        defaultValue,
        name: inputName,
        ...rest
    } = props

    const {
        field: { onChange, value, name, ref }
    } = useController({
        name: inputName,
        control,
        rules,
        defaultValue: defaultValue ? defaultValue : ''
    })

    const handleChange = (event) => {
        onChange( event.target.value )
    }
    
    return (
        <FormControl error={error} fullWidth={fullWidth}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                labelId={labelId}
                value={value}
                label={label}
                name={name}
                error={error}
                onChange={handleChange}
                inputRef={ref}
                {...rest}
            >
                {options.map( ({value, label}) => {
                    return <MenuItem key={value} value={value}>{label}</MenuItem>
                })}
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    )
}