import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import useAxiosPublic from '../../../hooks/useAxiosPublic';
import {
  formErrorMsg
} from '../../../config/index'

import {
  Alert,
  ReactHookFormMuiInput
} from '../../../common/index'

import {
  Button,
  Typography,
  Container,
  Stack,
} from "@mui/material"

const Contact = ( { disableGutters } ) => {
  const axios = useAxiosPublic()
  const { handleSubmit, control, formState: { errors } } = useForm()
  const [ loading, setLoading ] = useState(false)
  const [ alert, setAlert ] = useState({
    open: false
  })
  const { open, message, severity } = alert

  const onSubmit = async formData => {
    console.log(formData, 'formData')
    try {
      setLoading(true)
      const response = await axios.post('/contact', {
        ...formData
      })
      console.log(response.data, 'formData')
      setAlert({
        open: true,
        message: 'Message sent',
        severity: 'success'
      })
    } catch (err) {
      console.log(err.response.data.message, 'request error')
      setAlert({
        open: true,
        message: err.response.data.message,
        severity: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container 
      disableGutters={disableGutters}
      maxWidth="xs"
      sx={{ 
        margin: "30px auto"
      }}
    >
      <Alert
        open={open}
        message={message}
        severity={severity}
        onClose={() => setAlert( {...alert, open: false} )}
      />
      <Typography component="h1" variant="h4" align="center" mb={4}>How can we help you today?</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <ReactHookFormMuiInput
            control={control}
            id="name"
            name="name"
            label="Name"
            fullWidth
            autoComplete="name"
            rules={{required: false}}
            error={!!errors.name}
            helperText={errors.name && errors.name?.message}
          />

          <ReactHookFormMuiInput
            control={control}
            id="email"
            name="email"
            label="Email address"
            fullWidth
            autoComplete="email"
            rules={{required: formErrorMsg.required, pattern: { value: /^\S+@\S+$/i, message: formErrorMsg.invalidEmail}}}
            error={!!errors.email}
            helperText={errors.email && errors.email?.message}
          />
                    
          <ReactHookFormMuiInput
            control={control}
            id="phoneNumber"
            name="phoneNumber"
            label="Phone number"
            fullWidth
            autoComplete="phoneNumber"
            rules={{required: false}}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber && errors.phoneNumber?.message}
          />

          <ReactHookFormMuiInput
            control={control}
            id="message"
            name="message"
            label="Message"
            fullWidth
            autoComplete="message"
            multiline
            rows={5}
            rules={{required: formErrorMsg.required}}
            error={!!errors.message}
            helperText={errors.message && errors.message?.message}
          />

          <Button
            type="submit"
            fullWidth
          >
            {loading ? 'Please wait...' : 'Submit'}
          </Button>
        </Stack>
      </form>
    </Container>
  );
}

export default Contact