import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import siteLogo from '../../../assets/images/jhare-logo.png'
import useAxiosPublic from '../../../hooks/useAxiosPublic'
import useAuth from '../../../hooks/useAuth'

import {
  formErrorMsg
} from '../../../config/index'

import {
  Alert,
  MuiRouterLink,
  ReactHookFormMuiInput
} from '../../../common/index'

import {
  Avatar,
  Button,
  Typography,
  Container,
  Stack,
  InputAdornment,
  IconButton
} from "@mui/material";

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const SignUp = () => {
  const axios                                                   = useAxiosPublic()
  const navigate                                                = useNavigate()
  const { setAuth }                                             = useAuth()
  const [ loading, setLoading ]                                 = useState(false)
  const [ showPassword, setShowPassword ]                       = useState(false)
  const { handleSubmit, control, watch, formState: { errors } } = useForm()
  const [ alert, setAlert ]                                     = useState({open: false})
  const { open, message, severity }                             = alert

  const onSubmit = async formData => {
    try {
      setLoading(true)
      const response = await axios.post('/create-user', {
        ...formData
      })
      setAuth(response.data)
      navigate('/shipments', { replace: true })
    } catch (err) {
      const data    = err?.response?.data;
      const message = data ? data?.message : 'No server response';
      setAlert({
        open: true,
        message,
        severity: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container maxWidth="xs" sx={{ py: "30px" }}>
      <Alert
        open={open}
        message={message}
        severity={severity}
        onClose={() => setAlert( {...alert, open: false} )}
      />
      <Stack alignItems="center" pb={4} spacing={2}>
        <Avatar variant="square" src={siteLogo} srcSet={siteLogo} sx={{ backgroundColor: "#fff", padding: "5px", width: "auto", height: "auto" }} />
        <Typography component="h1" variant="h5">Sign up</Typography>
        <Typography variant="body2">Already have an account? <MuiRouterLink to="/login">Sign in</MuiRouterLink></Typography>
      </Stack >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <ReactHookFormMuiInput
            control={control}
            id="first_name"
            name="first_name"
            label="First Name"
            fullWidth
            autoComplete="first_name"
            autoFocus
            rules={{required: formErrorMsg.required}}
            error={!!errors.first_name}
            helperText={errors.first_name && errors.first_name?.message}
          />
        
          <ReactHookFormMuiInput
            control={control}
            id="last_name"
            name="last_name"
            label="Last Name"
            fullWidth
            autoComplete="last_name"
            rules={{required: formErrorMsg.required}}
            error={!!errors.last_name}
            helperText={errors.last_name && errors.last_name?.message}
          />
        
          <ReactHookFormMuiInput
            control={control}
            id="username"
            name="username"
            label="Username"
            fullWidth
            autoComplete="username"
            rules={{required: formErrorMsg.required, pattern: { value: /^[a-zA-Z0-9-_]{3,30}$/, message: formErrorMsg.invalidUsername}}}
            error={!!errors.username}
            helperText={errors.username && errors.username?.message}
          />

          <ReactHookFormMuiInput
            control={control}
            id="email"
            name="email"
            label="Email Address"
            fullWidth
            autoComplete="email"
            rules={{required: formErrorMsg.required, pattern: { value: /^\S+@\S+$/i, message: formErrorMsg.invalidEmail}}}
            error={!!errors.email}
            helperText={errors.email && errors.email?.message}
          />
          
          <ReactHookFormMuiInput
            control={control}
            type={showPassword ? 'text' : 'password'}
            id="password"
            name="password"
            label="Password"
            fullWidth
            autoComplete="password"
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword( !showPassword )}
                  onMouseDown={() => setShowPassword( !showPassword )}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }}
            rules={{required: formErrorMsg.required, min: { value: 5, message: formErrorMsg.minPass}}}
            error={!!errors.password}
            helperText={errors.password && errors.password?.message}
          />
          
          <ReactHookFormMuiInput
            control={control}
            type={showPassword ? 'text' : 'password'}
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            fullWidth
            autoComplete="confirmPassword"
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword( !showPassword )}
                  onMouseDown={() => setShowPassword( !showPassword )}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }}
            rules={{required: formErrorMsg.required, validate: { value: (val) => val === watch('password') || formErrorMsg.passMatch}}}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword && errors.confirmPassword?.message}
          />

          <Button
            type="submit"
            fullWidth
          >
            {loading ? 'Please wait...' : 'Sign Up'}
          </Button>

          <Typography align="center" variant="body2">By creating an account, you agree to Jhare's <MuiRouterLink to="/privacy-policy">Privacy policy</MuiRouterLink></Typography>
        </Stack>
      </form>
    </Container>
  );
}

export default SignUp