import { styled } from '@mui/material/styles';
import waves from '../../assets/svg/waves.svg';
import tilt from '../../assets/svg/tilt.svg';

export const WavyBorder = styled('div')(({ theme }) => ({
    height: '150px',
    width: 'calc(165% + 1.3px)',
    transform: 'rotateY(180deg)',
    backgroundImage: `url(${waves})`,
    [theme.breakpoints.down('md')]: {
        height: '100px',
    }
}));

export const WavyBorderBottom = () => {
    return <div style={{transform: "rotate(180deg)"}}><WavyBorder /></div>
}

export const TiltBorder = styled('div')(({ theme }) => ({
    height: '133px',
    width: 'calc(100% + 1.3px)',
    transform: 'rotateY(180deg)',
    backgroundImage: `url(${tilt})`,
    [theme.breakpoints.down('md')]: {
        height: '100px',
    }
}));

export const TiltBorderBottom = () => {
    return <div style={{transform: "rotate(180deg)"}}><TiltBorder /></div>
}