import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import {
  AdminNavBar
} from "../../../../common";

import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PeopleIcon from '@mui/icons-material/People';
import InboxIcon from '@mui/icons-material/Inbox';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import PlaceIcon from '@mui/icons-material/Place';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';

import useAuth from "../../../../hooks/useAuth";

import {
  ROLES
} from '../../../../config/index';

const userBase     = '/users'
const shipmentBase = '/shipments'
const trackingBase = '/tracking'

const subMenu = {
    shipment: [
        {
            name: 'All',
            id:   'All Shipments',
            icon: <LocalShippingOutlinedIcon />,
            path: `${shipmentBase}`
        },
        {
            name: 'Received',
            id:   'Received Shipments',
            icon: <InboxIcon />,
            path: `${shipmentBase}/received`
        },
        {
            name: 'Shipped',
            id:   'Shipped Shipments',
            icon: <FlightTakeoffIcon />,
            path: `${shipmentBase}/shipped`
        },
        {
            name: 'Delivered',
            id:   'Delivered Shipments',
            icon: <CheckCircleOutlineIcon />,
            path: `${shipmentBase}/delivered`
        },
        {
            name: 'On hold',
            id:   'On hold Shipments',
            icon: <StopCircleOutlinedIcon />,
            path: `${shipmentBase}/on-hold`
        },
        {
            name: 'RTS',
            id:   'RTS Shipments',
            icon: <AutorenewIcon />,
            path: `${shipmentBase}/rts`
        },
        {
            name: 'New',
            id:   'New Shipment',
            icon: <AddCircleOutlineIcon />,
            path: `${shipmentBase}/new`
        }
    ],
    users: [
        {
            name: 'All',
            id:   'All Users',
            icon: <PeopleOutlineIcon />,
            path: `${userBase}`
        },
        {
            name: 'New',
            id:   'New User',
            icon: <AddCircleOutlineIcon />,
            path: `${userBase}/new`
        }
    ],
    tracking: [
        {
            name: 'All',
            id:   'All Tracking',
            icon: <PlaceOutlinedIcon />,
            path: `${trackingBase}`
        },
        {
            name: 'Shipped',
            id:   'Shipped Tracking',
            icon: <FlightTakeoffIcon />,
            path: `${trackingBase}/shipped`
        },
        {
            name: 'Delivered',
            id:   'Delivered Tracking',
            icon: <CheckCircleOutlineIcon />,
            path: `${trackingBase}/delivered`
        },
        {
            name: 'New',
            id:   'New Tracking',
            icon: <AddCircleOutlineIcon />,
            path: `${trackingBase}/new`
        }
    ]
}

const adminMenu = [
    {
        name: 'Shipments',
        icon: <LocalShippingIcon />,
        open: true,
        children: subMenu.shipment
    },
    {
        name: 'Users',
        icon: <PeopleIcon />,
        open: true,
        children: subMenu.users
    },
    {
        name: 'Tracking',
        icon: <PlaceIcon />,
        open: true,
        children: subMenu.tracking
    }
]

const menu = [
    {
        name: 'Shipments',
        icon: <LocalShippingIcon />,
        open: true,
        children: subMenu.shipment.filter((el) => !['Received', 'RTS', 'New'].includes(el.name))
    },
]

const drawerWidth = 220;

const PrivateLayout = () => {
  const { auth } = useAuth();
  const userMenu = auth?.roles?.includes(ROLES.admin.key) ? adminMenu : menu
  return (
    <>
      <AdminNavBar menu={userMenu} drawerWidth={drawerWidth} />
      <Box
        component="main"
        sx={{ 
            flexGrow: 1,
            p: 3,
            ml: { sm: `${drawerWidth}px` },
            width: { sm: `calc(100% - ${drawerWidth}px)` } 
        }}
      >
        <Outlet />
      </Box>
    </>
  )
}

export default PrivateLayout