import ceo from '../../../assets/images/kunle-keshiro.jpeg'
import director from '../../../assets/images/tayo-keshiro.jpeg'
import Contact from "../../contact";

import {
  Typography,
  Container,
  Box,
  Stack,
  Divider
} from "@mui/material";

const About = () => {
  return (
    <Container sx={{ margin: "30px auto"}}>
      <Box maxWidth="500px" m="auto" mb={7}>
        <Typography component="h1" variant="h4" align="center" mb={4}>About us</Typography>
        <Typography mb={2}>JHARE EXPRESS LLC is a parcel forwarding company born out of Florida. We started operations in September 2019 as we realized that there was no means to ship to Nigeria around the Orlando area.</Typography>
        <Typography mb={2}>We are independently owned and are unaffiliated with any other individuals or third parties.</Typography>
        <Typography mb={2}>We are an IAC (indirect air carrier) that works with major airlines to get your shipment to you in a timely and safe manner. We comply with TSA standards and are the best at what we do.</Typography>
      </Box>
      <Divider />
      <Box mt={7} mb={7}>
        <Typography component="h1" variant="h4" align="center" mb={4}>The team</Typography>
        <Stack 
          flexDirection="row" 
          justifyContent="center"
          flexWrap="wrap"
          gap={{xs: "30px", md: "60px" }}
        >
          <Stack align="center">
            <Box 
              sx={{ 
                backgroundColor: "#fff", 
                padding: "5px", 
                width: "150px", 
                height: "auto",
              }}
            >
              <img 
                src={ceo} 
                alt="CEO" 
                style={{ 
                  width: "100%", 
                  borderRadius: "50%" 
                }}
              />
            </Box>
            <Typography mt={2} mb={1} sx={{fontWeight: "bold"}}>Kunle Keshiro</Typography>
            <Typography>CEO</Typography>
          </Stack>
          <Stack align="center">
            <Box 
              sx={{ 
                backgroundColor: "#fff", 
                padding: "5px", 
                width: "150px", 
                height: "auto",
              }}
            >
              <img 
                src={director} 
                alt="Director" 
                style={{ 
                  width: "100%", 
                  borderRadius: "50%" 
                }}
              />
            </Box>
            <Typography mt={2} mb={1} sx={{fontWeight: "bold"}}>Tayo Keshiro</Typography>
            <Typography>Director</Typography>
          </Stack>
        </Stack>
      </Box>
      <Divider />
      <Box mt={7}>
        <Contact disableGutters />
      </Box>
    </Container>
  );
}

export default About