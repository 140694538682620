import React from 'react'
import { useController } from "react-hook-form";
import TextField from "@mui/material/TextField";

export const Input = React.forwardRef((props, ref) => {
  return <input ref={ref} {...props} />
})

export const ReactHookFormMuiInput = ({ control, rules, defaultValue, name: inputName, ...rest }) => {
  const {
    field: { onChange, onBlur, value, name, ref }
  } = useController({
    name: inputName,
    control,
    rules,
    defaultValue: defaultValue ? defaultValue : ''
  })

  return (
    <TextField 
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      name={name}
      inputRef={ref}
      {...rest}
    />
  )
}