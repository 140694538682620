import { createTheme } from '@mui/material/styles';

const colors = {
  primary: "#fbf23f",
  secondary: "#666666",
  white: "#fff",
  black: "#000",
  selected: 0.14
};

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            rsm: 300,
            sm: 600,
            smd: 750,
            md: 900,
            slg: 1050,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        mode: 'light',
        primary: {
            main: colors.primary
        },
        secondary: {
            main: colors.secondary
        },
        action: {
            selectedOpacity: colors.selected
        }
    },
    typography: {
        h3: {
            lineHeight: "1.3"
        },
        h6: {
            lineHeight: "1.4"
        },
        body1: {
            wordBreak: "break-word",
        },
        body2: {
            wordBreak: "break-word",
        }
    },
    components: {
        MuiLink: {
            defaultProps: {
                color: 'inherit'
            }
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true,
                variant: "contained"
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: "outlined"
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: ({theme}) => ({
                    "&.Mui-focused": {
                        color: theme.palette.text['primary']
                    },
                    "&.Mui-error": {
                        color: theme.palette.error['main']
                    }
                })
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: ({ theme }) => ({
                    "&:-webkit-autofill": {
                        WebkitBoxShadow: `0 0 0 100px ${theme.palette.background['default']} inset`,
                        WebkitTextFillColor: theme.palette.text['primary']
                    }
                }),
                root: () => ({
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.text['primary'],
                        opacity: "0.23"
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.text['primary'],
                        opacity: "0.23"
                    },
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.error['main'],
                        opacity: "1"
                    }
                })
            },
        }
    }
});

export default theme;