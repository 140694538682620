import { Link, useLocation } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

export const PaginationLinks = ( {pages, mt} ) => {
  const location = useLocation();
  const params   = new URLSearchParams(location.search);
  const page     = parseInt(params.get('page') || '1', 10);
  const search   = params.get('search');
  return (
    <>
      {pages > 1 && <Stack mt={mt}>
        <Pagination
          page={page}
          count={pages}
          renderItem={(item) => (
            <PaginationItem
              component={Link}
              to={`${location.pathname}${item.page === 1 ? `${search ? `?search=${search}` : ''}` : `?${search ? `page=${item.page}&search=${search}` : `page=${item.page}`}`}`}
              {...item}
            />
          )}
        />
      </Stack>}
    </>
  );
}