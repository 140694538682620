import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { siteURL } from '../../../../config/index';
import useTitle from '../../../../hooks/useTitle';

import {
  ROLES,
  formErrorMsg
} from '../../../../config/index'

import {
  Alert,
  ReactHookFormMuiInput,
  ReactHookFormMuiSelect
} from '../../../../common/index'

import {
  Button,
  Stack,
  Container,
  InputAdornment,
  IconButton
} from "@mui/material";

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const userRoles = () => {
  const roles = []
  for (const key in ROLES) {
    if (ROLES.hasOwnProperty(key)) {
      roles.push({
        value: ROLES[key].key, 
        label: ROLES[key].name
      });
    }
  }
  return roles;
}

const NewUsers = () => {
  const axios                                                   = useAxiosPrivate()
  let location                                                  = useLocation()
  const { setTitle }                                            = useTitle()
  const [ isLoading, setLoading ]                               = useState(false)
  const [ showPassword, setShowPassword ]                       = useState( false )
  const { 
    handleSubmit, 
    control, 
    reset, 
    formState: { 
      errors 
    } 
  }                                                             = useForm()
  const [ alert, setAlert ]                                     = useState({open: false})
  const { open, message, severity }                             = alert

  useEffect(() => {
    setTitle('New User')
  }, [setTitle])

  useEffect(() => {
    reset()
  }, [reset, location.key])

  const onSubmit = async formData => {
    formData.roles = [formData.role]
    delete formData.role;
    try {
      setLoading(true)
      await axios.post(`${siteURL}/wp-json/wp/v2/users`, {
        ...formData
      })
      setAlert({
        open: true,
        message: 'User added successfully.',
        severity: 'success'
      })
    } catch (err) {
      setAlert({
        open: true,
        message: err.response.data.message,
        severity: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container disableGutters maxWidth="xs">
      <Alert
        privateLayout
        open={open}
        message={message}
        severity={severity}
        onClose={() => setAlert( {...alert, open: false} )}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
        <ReactHookFormMuiInput
            control={control}
            id="first_name"
            name="first_name"
            label="First Name"
            fullWidth
            autoComplete="first_name"
            autoFocus
            rules={{required: formErrorMsg.required}}
            error={!!errors.first_name}
            helperText={errors.first_name && errors.first_name?.message}
          />
        
          <ReactHookFormMuiInput
            control={control}
            id="last_name"
            name="last_name"
            label="Last Name"
            fullWidth
            autoComplete="last_name"
            rules={{required: formErrorMsg.required}}
            error={!!errors.last_name}
            helperText={errors.last_name && errors.last_name?.message}
          />
        
          <ReactHookFormMuiInput
            control={control}
            id="username"
            name="username"
            label="Username"
            fullWidth
            autoComplete="username"
            rules={{required: formErrorMsg.required, pattern: { value: /^[a-zA-Z0-9-_]{3,30}$/, message: formErrorMsg.invalidUsername}}}
            error={!!errors.username}
            helperText={errors.username && errors.username?.message}
          />

          <ReactHookFormMuiInput
            control={control}
            id="email"
            name="email"
            label="Email Address"
            fullWidth
            autoComplete="email"
            rules={{required: formErrorMsg.required, pattern: { value: /^\S+@\S+$/i, message: formErrorMsg.invalidEmail}}}
            error={!!errors.email}
            helperText={errors.email && errors.email?.message}
          />

          <ReactHookFormMuiSelect
            control={control}
            id="role"
            name="role"
            label="Role"
            fullWidth
            autoComplete="role"
            rules={{required: formErrorMsg.required}}
            error={!!errors.role}
            helperText={errors.role && errors.role?.message}
            options={userRoles()}
          />
          
          <ReactHookFormMuiInput
            control={control}
            type={showPassword ? 'text' : 'password'}
            id="password"
            name="password"
            label="Password"
            fullWidth
            autoComplete="password"
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword( !showPassword )}
                  onMouseDown={() => setShowPassword( !showPassword )}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }}
            rules={{required: formErrorMsg.required, min: { value: 5, message: formErrorMsg.minPass}}}
            error={!!errors.password}
            helperText={errors.password && errors.password?.message}
          />

          <Button
            type="submit"
            fullWidth
          >
            {isLoading ? 'Please wait...' : 'Add new user'}
          </Button>
        </Stack>
      </form>
    </Container>
  )
}

export default NewUsers