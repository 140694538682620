import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Alert = ( { message, severity, open, onClose, privateLayout } ) => {
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: privateLayout ? 'right' : 'center'
            }} 
            open={open} 
            autoHideDuration={4000}
            onClose={onClose}
        >
            <MuiAlert 
                elevation={3}
                onClose={onClose}
                severity={severity}
                sx={{ 
                    width: '100%' 
                }}
            >
                {message}
            </MuiAlert>
        </Snackbar>
    )
}

export default Alert