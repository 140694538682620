import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';

export const Table = ( props ) => {
    const {
        height,
        width,
        rows,
        columns,
        pageSize,
        rowsPerPageOptions,
        checkboxSelection
    } = props;

    const tableHeight = height || 400;
    const tableWidth = width || '100%';

    return (
        <Box sx={{ height: tableHeight, width: tableWidth }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={pageSize || 5}
                rowsPerPageOptions={rowsPerPageOptions || [5]}
                checkboxSelection={checkboxSelection}
            />
        </Box>
    );
}