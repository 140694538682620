import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Loading = ( {color, size, thickness, privateLayout, wrapperHeight} ) => {
  if ( privateLayout ) {
    wrapperHeight = 'calc(100vh - 112px)'
  }
  return (
    <Box 
        sx={{ 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: wrapperHeight || 'calc(100vh - 497.88px)'
        }}
    >
        <CircularProgress 
            color={color || 'secondary'}
            size={size || 60}
            thickness={thickness || 3.6}
        />
    </Box>
  )
}

export default Loading