import React from 'react'
import { useController } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

export const ReactHookFormMuiAutocomplete = ( props ) => {
    const {
        multiple,
        control,
        rules,
        label,
        placeholder,
        error,
        helperText,
        options,
        defaultValue,
        name: inputName,
        getOptionLabel,
        onInputChange,
        textFieldProps,
        ...rest
    } = props

    const {
        field: { onChange, onBlur, value, name, ref }
    } = useController({
        name: inputName,
        control,
        rules,
        defaultValue: defaultValue ? defaultValue : multiple ? [] : null
    })

    const handleChange = (event, newValue) => {
        onChange( newValue )
    }

    return (
        <Autocomplete
            multiple={multiple}
            onChange={handleChange}
            onInputChange={onInputChange}
            onBlur={onBlur}
            value={value}
            name={name}
            {...rest}
            options={options}
            filterOptions={x => x}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            renderOption={(props, option) => {
                return <li {...{...props, key: option.value}}>{option.label}</li>
            }}
            renderInput={(params) => (
                <FormControl error={error} fullWidth>
                    <TextField
                        {...params}
                        error={error}
                        label={label}
                        placeholder={placeholder}
                        inputRef={ref}
                        {...textFieldProps}
                    />
                    {helperText && <FormHelperText>{helperText}</FormHelperText>}
                </FormControl>
            )}
        />
    )
}