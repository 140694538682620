import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import useAxiosPublic from '../../../hooks/useAxiosPublic'

import {
  formErrorMsg
} from '../../../config/index'

import {
  Alert,
  ReactHookFormMuiInput
} from '../../../common/index'

import {
  Stack,
  Button,
  Container,
  IconButton,
  Typography,
  InputAdornment
} from "@mui/material";

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordReset = () => {
  const axios                             = useAxiosPublic();
  const navigate                          = useNavigate();
  const [ showPassword, setShowPassword ] = useState(false);
  const [ loading, setLoading ]           = useState(false);
  const [ codeSent, setCodeSent ]         = useState(false);
  const {
    handleSubmit, 
    control,
    getValues,
    formState: {
      errors 
    }
  }                                       = useForm();
  const {
    handleSubmit: handleSubmit2, 
    control: control2,
    watch, 
    formState: {
      errors: errors2 
    }
  }                                       = useForm();
  const [ alert, setAlert ]               = useState({open: false})
  const { open, message, severity }       = alert

  const onSubmit = async formData => {
    try {
      setLoading(true)
      await axios.post(`/reset-password-code`, {
        ...formData
      })
      setCodeSent(true)
      setAlert({
        open: true,
        message: 'A password reset code has been sent to your email address.',
        severity: 'success'
      })
    } catch (err) {
      setAlert({
        open: true,
        message: err.response.data.message,
        severity: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const onSubmit2 = async formData => {
    try {
      setLoading(true)
      await axios.post('/reset-password', {
        ...formData
      })
      navigate('/login', {
        state: {
          passwordReset: true
        } 
      })
    } catch (err) {
      setAlert({
        open: true,
        message: err.response.data.message,
        severity: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container maxWidth="xs" sx={{ py: "30px" }}>
      <Alert
        open={open}
        message={message}
        severity={severity}
        onClose={() => setAlert( {...alert, open: false} )}
      />
      <Typography component="h1" variant="h4" align="center" mb={4}>Reset your password</Typography>
      {!codeSent && <form onSubmit={handleSubmit(onSubmit)}>
        <ReactHookFormMuiInput
          control={control}
          id="user_login"
          name="user_login"
          label="Email Address or Username"
          fullWidth
          autoComplete="user_login"
          rules={{required: formErrorMsg.required}}
          error={!!errors.user_login}
          helperText={errors.user_login && errors.user_login?.message}
        />

        <Button
          type="submit"
          fullWidth
          sx={{
            mt: 3
          }}
        >
          {loading ? 'Please wait...' : 'Send code'}
        </Button>
      </form>}
    
      {codeSent && <form onSubmit={handleSubmit2(onSubmit2)}>
        <Stack spacing={3}>
          <ReactHookFormMuiInput
            control={control2}
            id="user_login"
            name="user_login"
            label="Email Address or Username"
            fullWidth
            autoComplete="user_login"
            rules={{required: false}}
            error={!!errors.user_login}
            helperText={errors.user_login && errors.user_login?.message}
            defaultValue={getValues('user_login')}
            sx={{
              display: "none"
            }}
          />

          <ReactHookFormMuiInput
            control={control2}
            id="code"
            name="code"
            label="Code"
            fullWidth
            autoComplete="code"
            rules={{required: formErrorMsg.required}}
            error={!!errors2.code}
            helperText={errors2.code && errors2.code?.message}
          />
          
          <ReactHookFormMuiInput
            control={control2}
            type={showPassword ? 'text' : 'password'}
            id="new_password"
            name="new_password"
            label="New password"
            fullWidth
            autoComplete="password"
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword( !showPassword )}
                  onMouseDown={() => setShowPassword( !showPassword )}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }}
            rules={{required: formErrorMsg.required, min: { value: 5, message: formErrorMsg.minPass}}}
            error={!!errors2.new_password}
            helperText={errors2.new_password && errors2.new_password?.message}
          />

          <ReactHookFormMuiInput
            control={control2}
            type={showPassword ? 'text' : 'password'}
            id="confirm_password"
            name="confirm_password"
            label="Confirm new password"
            fullWidth
            autoComplete="password"
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword( !showPassword )}
                  onMouseDown={() => setShowPassword( !showPassword )}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }}
            rules={{required: formErrorMsg.required, validate: { value: (val) => val === watch('new_password') || formErrorMsg.passMatch}}}
            error={!!errors2.confirm_password}
            helperText={errors2.confirm_password && errors2.confirm_password?.message}
          />

          <Button
            type="submit"
            fullWidth
          >
            {loading ? 'Please wait...' : 'Reset password'}
          </Button>
        </Stack>
      </form>}
    </Container>
  )
}

export default PasswordReset