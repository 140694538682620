import axios from 'axios';
import { apiBase } from './constants'

export const axiosPublic = axios.create({
    baseURL: apiBase,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const axiosPrivate = axios.create({
    baseURL: apiBase,
    headers: {
        'Content-Type': 'application/json',
    },
});