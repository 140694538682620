import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import useTitle from '../../../../hooks/useTitle';
import useSplitPathname from '../../../../hooks/useSplitPathname';

import {
  shipmentStatuses
} from '../../../../config/index'

import {
  Loading,
  MuiRouterLink
} from '../../../../common/index'

import {
  Stack,
  Container,
  Typography
} from '@mui/material'

import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const HorizontalStack = styled(Stack)(({ theme }) => ({
  alignItems: "flex-start",
  flexDirection: "row",
  flexWrap: "nowrap",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1)
}))

const SingleTracking = () => {
  const { setTitle }                      = useTitle();
  const path                              = useSplitPathname();
  const trackingID                        = path[1]
  const theme                             = useTheme();
  const navigate                          = useNavigate();
  const axios                             = useAxiosPrivate();
  const [ loading, setLoading ]           = useState(true)
  const [ trackingData, setTrackingData ] = useState({});

  useEffect(() => {
    setTitle('Tracking Details')
  }, [setTitle])

  useEffect( () => {
    const controller = new AbortController();
    const getTrackingData = async () => {
      try {
        setLoading(true)
        const response = await axios.get(`/tracking/${trackingID}`, {
          signal: controller.signal
        });
        setTrackingData( response.data )
        setLoading(false)
      } catch (err) {
        if (err.code !== 'ERR_CANCELED') {
          //console.log(err.response.data, 'Error');
        }
      }
    }
    getTrackingData()
    return () => {
      controller.abort();
    }
  }, [axios, trackingID])

  const deleteTracking = async (event, ID) => {
    event.preventDefault()
    if ( ! window.confirm('Are you sure you want to delete this data?') ) {
      return false;
    }
    try {
      await axios.delete(`/tracking/${ID}`)
      navigate('/tracking')
    } catch (error) {
      console.log(error.response, 'request error')
    }
  }

  const editTracking = async (event, url) => {
    event.preventDefault()
    navigate(url)
  }

  return (
    <Container disableGutters maxWidth="md">
      {loading && <Loading privateLayout />}
      {!loading && trackingData.ID && <>
        <Stack>
          <HorizontalStack>
            <CalendarMonthIcon sx={{ color: "#6c6c6c" }} />
            <Typography>{trackingData.date && new Date(trackingData?.date).toLocaleString()}</Typography>
          </HorizontalStack>
          <HorizontalStack>
            <ShareLocationIcon sx={{ color: "#6c6c6c" }} />
            <Typography>{trackingData.tracking_number}</Typography>
          </HorizontalStack>
          <HorizontalStack>
            <LocalOfferOutlinedIcon sx={{ color: "#6c6c6c" }} />
            <Typography>{shipmentStatuses.find((el) => el.value === trackingData.status)?.label}</Typography>
          </HorizontalStack>
          <Stack 
            flexDirection="row"
            gap="10px"
          >
            <EditIcon
              fontSize="medium"
              aria-label="edit"
              sx={{
                color: "#000",
                padding: '5px',
                boxSizing: 'content-box',
                cursor: 'pointer'
              }}
              onClick={(e) => editTracking(e, `/tracking/edit/${trackingData.ID}`)}
            />
            <DeleteIcon
              fontSize="medium"
              aria-label="delete"
              sx={{
                color: "#ff0000",
                padding: '5px',
                boxSizing: 'content-box',
                cursor: 'pointer'
              }}
              onClick={(e) => deleteTracking(e, trackingData.ID)}
            />
          </Stack>
          <Typography mt={theme.spacing(1.5)} fontWeight="medium">Shipments</Typography>
          <Stack flexDirection="row" gap="10px" flexWrap="wrap" mt={theme.spacing(1.5)}>
            {trackingData.shipments.map(( shipment ) => 
              (
                <MuiRouterLink
                  key={shipment.ID}
                  to={`/shipments/${shipment.ID}`}
                  underline="always"
                >
                  {shipment.description}
                </MuiRouterLink>
              )
            )}
          </Stack>
          <Typography mt={theme.spacing(2.5)} fontWeight="medium">Tracking info</Typography>
          <Typography
            mt={theme.spacing(1.5)}
            sx={{
              whiteSpace: "pre-wrap"
            }}
          >
            {trackingData.tracking_info}
          </Typography>
        </Stack>
      </>}
    </Container>
  )
}

export default SingleTracking