import React from 'react'

import { 
  Stack,
  Button,
  Typography,
  Container
} from "@mui/material";

const NotFound = () => {
  return (
    <Container maxWidth="xs" sx={{ py: "30px" }}>
      <Typography component="h1" variant="h3" align="center">404</Typography>
      <Typography variant="h5" align="center" m={4}>Hmmm... The page you're trying to access does not exist.</Typography>
      <Stack direction="row" justifyContent="center">
        <Button
          type="submit"
          size="large"
          href="/"
        >
          Go Home
        </Button>
      </Stack>
    </Container>
  )
}

export default NotFound