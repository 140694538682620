import { TiltBorder, TiltBorderBottom } from '../styles.js'
import { useTheme } from '@mui/material/styles'

import {
    Typography,
    Container,
    Grid,
    Paper,
    Card,
    CardContent,
    Avatar,
    Stack
} from "@mui/material";

const stringAvatar = (name) => {
    name = name.split(' ');
    const fName = name[0] && name[0][0]
    const lName = name[1] && name[1][0]
    return {
        sx: {
            bgcolor: '#ffeb3b',
            color: 'inherit',
        },
        children: `${fName}${lName}`,
    };
}

const Reviews = () => {
    const theme = useTheme()

    let bold = theme.typography.fontWeightBold
    let altColor = theme.palette.common.black
    if( theme.palette.mode === 'light' ){
        altColor = theme.palette.common.white
    }
    
    return (
        <Paper elevation={0} sx={{width: '100%', backgroundColor: '#002233', overflow: 'hidden'}}>
            <TiltBorder />
            <Container>
                <Typography variant="h4" align="center" py={10} sx={{fontWeight: bold, color: altColor}}>What customers are saying</Typography>
                <Grid container alignItems="flex-end" justifyContent="center" gap={3} pb={10}>
                    <Grid item>
                        <Card>
                            <CardContent sx={{ width: 350, height: 200, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <Typography>
                                    Very fast and efficient.  Sent my packages on a Friday,  arrived in Lagos on Tuesday. It was door to door delivery.  Extremely professional and dependable.  Really appreciate your services.
                                </Typography>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Avatar {...stringAvatar('Niki G.')} />
                                    <Typography>Niki G.</Typography>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item>
                        <Card>
                            <CardContent sx={{ width: 350, height: 200, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <Typography>
                                    Amazing company. From Customer service to delivery. They definitely gave me a peace of mind. Definitely coming back!
                                </Typography>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Avatar {...stringAvatar('Habib L.')} />
                                    <Typography>Habib L.</Typography>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item>
                        <Card>
                            <CardContent sx={{ width: 350, height: 200, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <Typography>
                                    Customer relation is really good and delivery was without hassle
                                </Typography>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Avatar {...stringAvatar('Aliz D.')} />
                                    <Typography>Aliz D.</Typography>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <TiltBorderBottom />
        </Paper>
    )
}

export default Reviews