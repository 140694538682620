import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import useTitle from '../../../hooks/useTitle';
import { siteURL } from '../../../config/index'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

import {
  states,
  formErrorMsg
} from '../../../config/index'

import {
  Alert,
  Loading,
  ReactHookFormMuiInput,
  ReactHookFormMuiSelect
} from '../../../common/index'

import {
  Box,
  Button,
  Typography,
  Container,
  Stack,
  InputAdornment,
  IconButton
} from "@mui/material";

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import LockResetIcon from '@mui/icons-material/LockReset';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const menus = [
  {
    icon: <PersonOutlineIcon />,
    name: 'Account',
    desc: 'Personal information'
  },
  {
    icon: <FmdGoodOutlinedIcon />,
    name: 'Address',
    desc: 'Shipping addresses'
  },
  {
    icon: <LockResetIcon />,
    name: 'Change password',
    desc: 'Change your password'
  },
  {
    icon: <DeleteForeverIcon />,
    name: 'Delete account',
    desc: 'Delete your account'
  }
]

const Item = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  flexDirection: "row",
  gap: theme.spacing(2),
  padding: theme.spacing(1),
  border: "1px solid #EDEEF2",
  marginBottom: theme.spacing(2),
  borderRadius: theme.spacing(2),
  cursor: "pointer"
}))

const ItemIcon = styled(Box)(({ theme, selected }) => ({
  backgroundColor: selected ? theme.palette.primary.main : "#EDEEF2",
  borderRadius: theme.spacing(1),
  padding: "6px 8px 2px"
}))

const TabContent = styled(Stack)(() => ({
  display: "flex"
}))

const Account = () => {
  const { setTitle }                                                   = useTitle()
  const theme                                                          = useTheme()
  const axios                                                          = useAxiosPrivate()
  const navigate                                                       = useNavigate();
  const [loading, setLoading]                                          = useState(true)
  const [btnLoading, setBtnLoading]                                    = useState(false)
  const [selectedMenu, setSelectedMenu]                                = useState('Account')
  const [showPassword, setShowPassword]                                = useState( false )
  const [showAccoutPassword, setShowAccoutPassword]                    = useState( false )
  const { handleSubmit, control, watch, reset, formState: { errors } } = useForm({ shouldUnregister: true })
  const [ alert, setAlert ]                                            = useState({open: false})
  const { open, message, severity }                                    = alert

  useEffect(() => {
    setTitle('Account')
  }, [setTitle])

  const handleMenuToggle = (name) => {
      setSelectedMenu(name)
  }

  const submitButtonText = () => {
    let text;
    if (btnLoading) {
      text = 'Please wait...'
    } else if ( selectedMenu === 'Delete account' ) {
      text = 'Delete account'
    } else {
      text = 'Save changes'
    }
    return text
  }
  
  useEffect(() => {
    const controller = new AbortController();
    const getData = async () => {
      try {
        setLoading(true)
        const response = await axios.get(`${siteURL}/wp-json/wp/v2/users/me`, {
          signal: controller.signal,
        })
        reset({
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          username: response.data.username,
          email: response.data.email,
          phone_number: response.data.meta?.phone_number,
          pickup_address: response.data.meta?.pickup_address,
          pickup_city: response.data.meta?.pickup_city,
          pickup_state: response.data.meta?.pickup_state,
          delivery_address: response.data.meta?.delivery_address,
          delivery_city: response.data.meta?.delivery_city,
          delivery_state: response.data.meta?.delivery_state
        })
        setLoading(false)
      } catch (err) {
        if (err.code !== 'ERR_CANCELED') {
          //console.log(err.response.data, 'Error');
        }
      }
    }
    getData()
    return () => {
      controller.abort();
    }
  }, [axios, reset])

  const onSubmit = async formData => {
    let deleteUser     = false;
    let changePassword = false;
    const meta = {}
    const keys = [
      'phone_number',
      'pickup_address',
      'pickup_city',
      'pickup_state',
      'delivery_address',
      'delivery_city',
      'delivery_state'
    ]
    for (const key in formData) {
      if ( formData.hasOwnProperty( key ) ) {
        if ( keys.includes( key ) ) {
          meta[key] = formData[key]
          delete formData[key];
        }
        if ( 'delete_account_password' === key ) {
          deleteUser = true;
        } else if ( 'password' === key ) {
          changePassword = true;
        }
      }
    }
    formData.meta = meta
    try {
      setBtnLoading(true)
      if ( deleteUser ) {
        await axios.post('/delete-user', {
          ...formData
        })
        navigate('/')
      } else if ( changePassword ) {
        await axios.post('/change-password', {
          ...formData
        })
      } else {
        await axios.post(`${siteURL}/wp-json/wp/v2/users/me`, {
          ...formData
        })
      }
      setAlert({
        open: true,
        message: 'Updated successfully.',
        severity: 'success'
      })
    } catch (err) {
      setAlert({
        open: true,
        message: err.response.data.message,
        severity: 'error'
      })
    } finally {
      setBtnLoading(false)
    }
  }

  return (
    <Container disableGutters maxWidth="md">
      <Alert
        privateLayout
        open={open}
        message={message}
        severity={severity}
        onClose={() => setAlert( {...alert, open: false} )}
      />
      {loading && <Loading privateLayout />}
      {!loading && <Stack gap={5} flexDirection={{ slg: "row" }}>
        <Stack>
          <Typography variant="h6">Settings</Typography>
          <Box mt={2}>
            {menus.map(({ name, icon, desc }) => (
              <Item 
                  key={name}
                  onClick={() => handleMenuToggle(name)}
                  backgroundColor={ selectedMenu === name && "#EDEEF2" }
              >
                <ItemIcon 
                    selected={ selectedMenu === name ? 1 : 0 }
                >
                  {icon}
                </ItemIcon>
                <Box>
                  <Typography fontWeight="medium">{name}</Typography>
                  <Typography variant="body2">{desc}</Typography>
                </Box>
              </Item>
            ))}
          </Box>
        </Stack>

        <Stack sx={{flex: 3}}>
          <Typography variant="h5">{selectedMenu}</Typography>
          <Box mt={3}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack>
                {selectedMenu === "Account" && <TabContent>
                  <Stack gap={theme.spacing(2)} flexDirection={{ sm: "column", smd: "row" }} mb={3}>
                    <ReactHookFormMuiInput
                      control={control}
                      id="first_name"
                      name="first_name"
                      label="First name"
                      autoComplete="first_name"
                      fullWidth
                      autoFocus
                      rules={{required: formErrorMsg.required}}
                      error={!!errors.first_name}
                      helperText={errors.first_name && errors.first_name?.message}
                    />
                  
                    <ReactHookFormMuiInput
                      control={control}
                      id="last_name"
                      name="last_name"
                      label="Last name"
                      fullWidth
                      autoComplete="last_name"
                      rules={{required: formErrorMsg.required}}
                      error={!!errors.last_name}
                      helperText={errors.last_name && errors.last_name?.message}
                    />
                  </Stack>

                  <Stack gap={theme.spacing(2)} flexDirection={{ sm: "column", smd: "row" }} mb={3}>
                    <ReactHookFormMuiInput
                      control={control}
                      id="email"
                      name="email"
                      label="Email address"
                      fullWidth
                      autoComplete="email"
                      rules={{required: formErrorMsg.required, pattern: { value: /^\S+@\S+$/i, message: formErrorMsg.invalidEmail}}}
                      error={!!errors.email}
                      helperText={errors.email && errors.email?.message}
                    />
                    
                    <ReactHookFormMuiInput
                      control={control}
                      id="phone_number"
                      name="phone_number"
                      label="Phone number"
                      fullWidth
                      autoComplete="phone_number"
                      rules={{required: false}}
                      error={!!errors.phone_number}
                      helperText={errors.phone_number && errors.phone_number?.message}
                    />
                  </Stack>
                </TabContent>}

                {selectedMenu === "Address" && <TabContent>
                  <Typography fontWeight="medium" mb={2}>Pickup address</Typography>
                  <Stack gap={theme.spacing(2)} flexDirection="row" mb={3}>
                    <ReactHookFormMuiInput
                      control={control}
                      id="pickup_address"
                      name="pickup_address"
                      label="Address"
                      autoComplete="address"
                      fullWidth
                      autoFocus
                      rules={{required: formErrorMsg.required}}
                      error={!!errors.pickup_address}
                      helperText={errors.pickup_address && errors.pickup_address?.message}
                    />
                  </Stack>

                  <Stack gap={theme.spacing(2)} flexDirection={{ sm: "column", smd: "row" }} alignItems="center" mb={3}>
                    <ReactHookFormMuiInput
                      control={control}
                      id="pickup_city"
                      name="pickup_city"
                      label="City"
                      fullWidth
                      autoComplete="city"
                      rules={{required: formErrorMsg.required}}
                      error={!!errors.pickup_city}
                      helperText={errors.pickup_city && errors.pickup_city?.message}
                    />

                    <ReactHookFormMuiSelect
                      control={control}
                      id="pickup_state"
                      name="pickup_state"
                      label="State"
                      fullWidth
                      autoComplete="state"
                      rules={{required: formErrorMsg.required}}
                      error={!!errors.pickup_state}
                      helperText={errors.pickup_state && errors.pickup_state?.message}
                      options={states}
                    />
                  </Stack>

                  <Typography fontWeight="medium" mb={2}>Delivery address</Typography>
                  <Stack gap={theme.spacing(2)} flexDirection="row" mb={3}>
                    <ReactHookFormMuiInput
                      control={control}
                      id="delivery_address"
                      name="delivery_address"
                      label="Address"
                      autoComplete="address"
                      fullWidth
                      rules={{required: formErrorMsg.required}}
                      error={!!errors.delivery_address}
                      helperText={errors.delivery_address && errors.delivery_address?.message}
                    />
                  </Stack>

                  <Stack gap={theme.spacing(2)} flexDirection={{ sm: "column", smd: "row" }} alignItems="center" mb={3}>
                    <ReactHookFormMuiInput
                      control={control}
                      id="delivery_city"
                      name="delivery_city"
                      label="City"
                      fullWidth
                      autoComplete="city"
                      rules={{required: formErrorMsg.required}}
                      error={!!errors.delivery_city}
                      helperText={errors.delivery_city && errors.delivery_city?.message}
                    />

                    <ReactHookFormMuiSelect
                      control={control}
                      id="delivery_state"
                      name="delivery_state"
                      label="State"
                      fullWidth
                      autoComplete="state"
                      rules={{required: formErrorMsg.required}}
                      error={!!errors.delivery_state}
                      helperText={errors.delivery_state && errors.delivery_state?.message}
                      options={states}
                    />
                  </Stack>
                </TabContent>}
                
                {selectedMenu === "Change password" && <TabContent>
                  <Stack mb={3}>
                    <ReactHookFormMuiInput
                      control={control}
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      name="password"
                      label="Current password"
                      fullWidth
                      autoFocus
                      autoComplete="password"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword( !showPassword )}
                            onMouseDown={() => setShowPassword( !showPassword )}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }}
                      rules={{required: formErrorMsg.required, min: { value: 5, message: formErrorMsg.minPass}}}
                      error={!!errors.password}
                      helperText={errors.password && errors.password?.message}
                    />
                  </Stack>

                  <Stack mb={3}>
                    <ReactHookFormMuiInput
                      control={control}
                      type={showPassword ? 'text' : 'password'}
                      id="new_password"
                      name="new_password"
                      label="New password"
                      fullWidth
                      autoComplete="password"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword( !showPassword )}
                            onMouseDown={() => setShowPassword( !showPassword )}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }}
                      rules={{required: formErrorMsg.required, min: { value: 5, message: formErrorMsg.minPass}}}
                      error={!!errors.new_password}
                      helperText={errors.new_password && errors.new_password?.message}
                    />
                  </Stack>
                    
                  <Stack mb={3}>
                    <ReactHookFormMuiInput
                      control={control}
                      type={showPassword ? 'text' : 'password'}
                      id="confirm_password"
                      name="confirm_password"
                      label="Confirm new password"
                      fullWidth
                      autoComplete="password"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword( !showPassword )}
                            onMouseDown={() => setShowPassword( !showPassword )}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }}
                      rules={{required: formErrorMsg.required, validate: { value: (val) => val === watch('new_password') || formErrorMsg.passMatch}}}
                      error={!!errors.confirm_password}
                      helperText={errors.confirm_password && errors.confirm_password?.message}
                    />
                  </Stack>
                </TabContent>}
                
                {selectedMenu === "Delete account" && <TabContent>
                  <Stack mb={3}>
                    <ReactHookFormMuiInput
                      control={control}
                      type={showAccoutPassword ? 'text' : 'password'}
                      id="delete_account_password"
                      name="delete_account_password"
                      label="Password"
                      fullWidth
                      autoFocus
                      autoComplete="password"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowAccoutPassword( !showAccoutPassword )}
                            onMouseDown={() => setShowAccoutPassword( !showAccoutPassword )}
                            edge="end"
                          >
                            {showAccoutPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }}
                      rules={{required: formErrorMsg.required, min: { value: 5, message: formErrorMsg.minPass}}}
                      error={!!errors.delete_account_password}
                      helperText={errors.delete_account_password && errors.delete_account_password?.message}
                    />
                  </Stack>
                </TabContent>}

                <Button
                  type="submit"
                  sx={{alignSelf: "flex-end"}}
                >
                  {submitButtonText()}
                </Button>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Stack>}
    </Container>
  );
}

export default Account