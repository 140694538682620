import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import useAxiosPublic from '../../../hooks/useAxiosPublic'

import {
  formErrorMsg,
  shipmentStatuses
} from '../../../config/index'

import {
  Alert,
  ReactHookFormMuiInput
} from '../../../common/index'

import {
  Button,
  Typography,
  Container,
  Stack,
  Box
} from "@mui/material";

const Track = () => {
  const axios = useAxiosPublic();
  const { handleSubmit, control, formState: { errors } } = useForm()
  const [ loading, setLoading ] = useState(false)
  const [ trackingData, setTrackingData ] = useState('')
  const [ alert, setAlert ] = useState({open: false})
  const { open, message, severity } = alert

  const onSubmit = async formData => {
    try {
      setTrackingData('')
      setLoading(true)
      const response = await axios.post('/track', {
        ...formData
      })
      if ( response.data ) {
        setTrackingData( response.data )
      } else {
        setAlert({
          open: true,
          message: 'We cannot find any shipment with that tracking number.',
          severity: 'error'
        })
      }
    } catch (err) {
      setAlert({
        open: true,
        message: err.response.data.message,
        severity: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container maxWidth="xs" sx={{ py: "30px" }}>
      <Alert
        privateLayout
        open={open}
        message={message}
        severity={severity}
        onClose={() => setAlert( {...alert, open: false} )}
      />
      <Typography component="h1" variant="h4" align="center" mb={4}>Track your item(s)</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
        <ReactHookFormMuiInput
            control={control}
            id="tracking_number"
            name="tracking_number"
            label="Enter tracking number"
            fullWidth
            autoComplete="tracking_number"
            autoFocus
            rules={{required: formErrorMsg.required}}
            error={!!errors.tracking_number}
            helperText={errors.tracking_number && errors.tracking_number?.message}
          />
          <Button
            type="submit"
            fullWidth
          >
            {loading ? 'Please wait...' : 'Submit'}
          </Button>
          {trackingData && <Box
            sx={{
              borderRadius: "5px",
              border: "1px solid #eaeaea",
              padding: "20px"
            }}
          >
            {trackingData.ID && <>
              <Typography mb={3}>Status: {shipmentStatuses.find((el) => el.value === trackingData.status)?.label}</Typography>
              <Typography
                sx={{
                  whiteSpace: "pre-wrap"
                }}
              >
              {trackingData.tracking_info}
              </Typography>
            </>}
            {!trackingData.ID && <Typography>{trackingData}</Typography>}
          </Box>}
        </Stack>
      </form>
    </Container>
  );
}

export default Track