import { DropzoneArea } from "mui-file-dropzone";
import { useController } from "react-hook-form";
import {
  FormHelperText
} from "@mui/material";
import '../styles/styles.css'

export const FileUpload = ( { control, rules, error, helperText, name: inputName, ...rest } ) => {
    const {
        field: { onChange, name, ref }
      } = useController({
        name: inputName,
        control,
        rules,
        defaultValue: [],
    })
    return (
        <div>
            <DropzoneArea
                previewGridClasses={{container: 'previewContainer'}}
                dropzoneClass={error ? 'dropzoneContainer dropzoneError' : 'dropzoneContainer'}
                onChange={onChange}
                name={name}
                inputRef={ref}
                showAlerts={false}
                alertSnackbarProps={{
                    autoHideDuration: 4000,
                    anchorOrigin: { 
                        vertical: 'top',
                        horizontal: 'right'
                    }
                }}
                {...rest}
            />
            {error && <FormHelperText sx={{marginTop: '3px'}} error={error}>{helperText}</FormHelperText>}
        </div>
    )
}