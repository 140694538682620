import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import useTitle from '../../../../hooks/useTitle';
import useAuth from '../../../../hooks/useAuth';
import useSplitPathname from '../../../../hooks/useSplitPathname';

import {
  ROLES,
  shipmentStatuses
} from '../../../../config/index'

import {
  Loading,
  MuiRouterLink,
} from '../../../../common/index'

import {
  Container,
  Stack,
  Typography,
  Avatar
} from '@mui/material'

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LocationSearchingOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const HorizontalStack = styled(Stack)(({ theme }) => ({
  alignItems: "flex-start",
  flexDirection: "row",
  flexWrap: "nowrap",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1)
}))

const Shipment = () => {
  const { setTitle }                      = useTitle();
  const { auth }                          = useAuth();
  const path                              = useSplitPathname();
  const shipmentID                        = path[1]
  const theme                             = useTheme();
  const navigate                          = useNavigate();
  const axios                             = useAxiosPrivate();
  const [ loading, setLoading ]           = useState(true)
  const [ shipmentData, setShipmentData ] = useState({});

  useEffect(() => {
    setTitle('Shipment Details')
  }, [setTitle])

  useEffect( () => {
    const controller = new AbortController();
    const getShipmentData = async () => {
      try {
        setLoading(true)
        const response = await axios.get(`/shipments/${shipmentID}`, {
          signal: controller.signal
        });
        setShipmentData( response.data )
        setLoading(false)
      } catch (err) {
        if (err.code !== 'ERR_CANCELED') {
          //console.log(err.response.data, 'Error');
        }
      }
    }
    getShipmentData()
    return () => {
      controller.abort();
    }
  }, [axios, shipmentID])

  const deleteShipment = async (event, ID) => {
    event.preventDefault()
    if ( ! window.confirm('Are you sure you want to delete this data?') ) {
      return false;
    }
    try {
      await axios.delete(`/shipments/${ID}`)
      navigate('/shipments')
    } catch (error) {
      console.log(error.response.data, 'delete error')
    }
  }

  const editShipment = async (event, url) => {
    event.preventDefault()
    navigate(url)
  }

  return (
    <Container disableGutters maxWidth="md">
      {loading && <Loading privateLayout />}
      {!loading && shipmentData.ID && <>
        <Stack>
          <HorizontalStack>
            <CalendarMonthIcon sx={{ color: "#6c6c6c" }} />
            <Typography>{shipmentData.date && new Date(shipmentData?.date).toLocaleString()}</Typography>
          </HorizontalStack>
          <HorizontalStack>
            <PersonOutlineOutlinedIcon sx={{ color: "#6c6c6c" }} />
            <Typography>{shipmentData.recipient_name}</Typography>
          </HorizontalStack>
          <HorizontalStack>
            <LocationSearchingOutlinedIcon sx={{ color: "#6c6c6c" }} />
            <Typography>{shipmentData.user_tracking}</Typography>
          </HorizontalStack>
          {auth?.roles?.some( role => role === ROLES.admin.key ) && <>
            <HorizontalStack>
              <ShareLocationIcon sx={{ color: "#6c6c6c" }} />
              {shipmentData.tracking_number && <MuiRouterLink
                to={`/tracking/${shipmentData.tracking_id}`}
              >
                {shipmentData.tracking_number}
              </MuiRouterLink>}
            </HorizontalStack>
            <HorizontalStack>
              <MyLocationIcon sx={{ color: "#6c6c6c" }} />
              <Typography>{shipmentData.sender_tracking}</Typography>
            </HorizontalStack>
          </>}
          <HorizontalStack>
            <LocalOfferOutlinedIcon sx={{ color: "#6c6c6c" }} />
            <Typography>{shipmentStatuses.find((el) => el.value === shipmentData.status)?.label}</Typography>
          </HorizontalStack>
          {auth?.roles?.some( role => role === ROLES.admin.key ) && <Stack 
            flexDirection="row"
            gap="10px"
          >
            <EditIcon
              fontSize="medium"
              aria-label="edit"
              sx={{
                color: "#000",
                padding: '5px',
                boxSizing: 'content-box',
                cursor: 'pointer'
              }}
              onClick={(e) => editShipment(e, `/shipments/edit/${shipmentData.ID}`)}
            />
            <DeleteIcon
              fontSize="medium"
              aria-label="delete"
              sx={{
                color: "#ff0000",
                padding: '5px',
                boxSizing: 'content-box',
                cursor: 'pointer'
              }}
              onClick={(e) => deleteShipment(e, shipmentData.ID)}
            />
          </Stack>}
          <Typography mt={theme.spacing(1.5)} fontWeight="medium">Description</Typography>
          <Typography mt={theme.spacing(1.5)}>{shipmentData.description}</Typography>
          {auth?.roles?.some( role => role === ROLES.admin.key ) && shipmentData.note && <>
          <Typography mt={theme.spacing(1.5)} fontWeight="medium">Note</Typography>
          <Typography mt={theme.spacing(1.5)}>{shipmentData.note}</Typography>
          </>}
          <Stack flexDirection="row" gap="20px" flexWrap="wrap" mt={theme.spacing(4)}>
            {shipmentData.attachment_urls?.map(( url ) => 
              (
                <Avatar key={url} variant="square" src={url} srcSet={url} sx={{width: "100%", height: "auto", borderRadius: "14px"}} />
              )
            )}
          </Stack>
        </Stack>
      </>}
    </Container>
  )
}

export default Shipment