import { Box } from "@mui/material";
import {
  TopBar, 
  NavBar, 
  Footer
} from "../../../../common";
import { Outlet } from 'react-router-dom'

const PublicLayout = () => {
  return (
    <>
      <TopBar />
      <NavBar />
      <Box
        sx={{
          minHeight: 'calc(100vh - 437.88px)'
        }}
      >
        <Outlet />
      </Box>
      <Footer />
    </>
  )
}

export default PublicLayout