import React from 'react'
import { useController } from "react-hook-form";
import TextField from "@mui/material/TextField";

export const ReactHookFormMuiTextarea = ( props ) => {
    const {
        control,
        rules,
        minRows,
        defaultValue,
        name: inputName,
        ...rest
    } = props

    const {
        field: { onChange, onBlur, value, name, ref }
    } = useController({
        name: inputName,
        control,
        rules,
        defaultValue: defaultValue ? defaultValue : ''
    })

    return (
        <TextField 
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            name={name}
            inputRef={ref}
            multiline
            minRows={minRows ? minRows : 3}
            {...rest}
        />
    )
}