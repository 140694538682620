import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import useAuth from "../../../../hooks/useAuth";
import useTitle from "../../../../hooks/useTitle";
import useLogout from "../../../../hooks/useLogout";

import {
    MuiRouterLink
  } from '../../../../common/index'

const stringAvatar = (name) => {
    if (name) {
        name = name.split(' ');
        const fName = name[0] && name[0][0]
        const lName = name[1] && name[1][0]
        return {
            sx: {
                bgcolor: '#ffeb3b',
                color: 'inherit',
            },
            children: `${fName}${lName}`,
        };
    }
}

const AdminNavBar = ({ menu, drawerWidth }) => {

    const theme = useTheme()
    const { auth } = useAuth()
    const { title } = useTitle()
    const logout = useLogout();
    const [menuItems, setMenuItems] = useState(menu)
    const [mobileOpen, setMobileOpen] = useState(false)
    const [anchorElUser, setAnchorElUser] = useState(null)

    const avatarMenu = [
        { 
            link: '/',
            name: 'Front page'
        },
        { 
            link: '/account',
            name: 'Account'
        },
        { 
            onClick: () => logout(),
            name: 'Logout'
        } 
    ];

    const handleMenuCollapseToggle = (name) => {
        const items = menuItems.map(
            el => el.name === name ? { ...el, open: !el.open } : el
        )
        setMenuItems(items)
    }

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    }

    const mobileDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    }

    const closeDrawer = () => {
        mobileOpen && mobileDrawerToggle();
    }

    const drawer = (
        <div>
            <Toolbar />
            <Divider />
            <List component="div">
                {menuItems.map(({ name: menu, id, icon, open, path, children }) => (
                    <Box key={menu}>
                        {children ? 
                        <>
                            <ListItemButton
                                onClick={() => handleMenuCollapseToggle(menu)}
                            >
                                <ListItemIcon>
                                    {icon}
                                </ListItemIcon>
                                <ListItemText primary={menu} />
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                {children.map(({ name: subMenu, icon, path, id }) => (
                                    <List 
                                        key={subMenu} 
                                        component="div"
                                        disablePadding
                                        onClick={closeDrawer}
                                    >
                                        <ListItemButton
                                            component={Link}
                                            //reloadDocument={subMenu === 'New' ? true : false}
                                            to={path}
                                            sx={{ pl: 4 }}
                                            selected={id === title}
                                        >
                                            <ListItemIcon>
                                                {icon}
                                            </ListItemIcon>
                                            <ListItemText primary={subMenu} />
                                        </ListItemButton>
                                    </List>
                                ))}
                            </Collapse>
                        </> 
                        : 
                        <>
                            <ListItemButton
                                component={Link}
                                to={path}
                                selected={id === title}
                            >
                                <ListItemIcon>
                                    {icon}
                                </ListItemIcon>
                                <ListItemText primary={menu} />
                            </ListItemButton>
                        </>}
                    </Box>
                ))}
            </List>
        </div>
    )

    return (
        <>
            <AppBar
                position="relative"
                sx={{
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar sx={{ position: 'relative' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={mobileDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6">{title}</Typography>
                    <Box sx={{ position: 'absolute', right: theme.spacing(2) }}>
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar {...stringAvatar(auth.full_name)} />
                        </IconButton>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {avatarMenu.map(({link, onClick, name}) => (
                                <MenuItem 
                                    disableGutters 
                                    key={name} 
                                    onClick={handleCloseUserMenu}
                                    sx={{
                                        padding: 0
                                    }}
                                >
                                    {link ? 
                                        <MuiRouterLink
                                            to={link} 
                                            underline="none"
                                            sx={{
                                                padding: '6px 16px',
                                                width: '100%'
                                            }}
                                        >
                                            {name}
                                        </MuiRouterLink> : 
                                        <Typography 
                                            onClick={onClick}
                                            sx={{
                                                padding: '6px 16px',
                                                width: '100%'
                                            }}
                                        >
                                            {name}
                                        </Typography>
                                    }
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="links"
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={mobileDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
        </>
    );
}

export default AdminNavBar;
