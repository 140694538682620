import { WavyBorder, WavyBorderBottom } from '../styles.js'
import { useTheme } from '@mui/material/styles'

import {
    Typography,
    Container,
    Grid,
    Paper,
    Card,
    CardContent
  } from "@mui/material"

import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled'

function Services() {
    const theme = useTheme()
    return (
        <Paper elevation={0} sx={{width: '100%', backgroundColor: '#002233', overflow: 'hidden'}}>
            <WavyBorder />
            <Container>
                <Grid container alignItems="flex-end" justifyContent="center" spacing={3} py={10}>
                    <Grid item xs={12} sm={10} md={4}>
                        <Card align="center" sx={{ height: 250, display: "flex", alignItems: "center" }}>
                            <CardContent>
                                <DirectionsBoatFilledIcon sx={{fontSize: "50px", color: "#009688"}} />
                                <Typography variant="h6" gutterBottom>SHOP FOR ME</Typography>
                                <Typography>
                                    We offer personal and business online shopping services from any where around the world for a percentage. In the USA we offer in store shopping and delivery to any part of the world. 
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={10} md={4}>
                        <Card align="center" sx={{ 
                            height: 350,
                            [theme.breakpoints.down('md')]: {
                                height: 250,
                            },
                            display: "flex",
                            alignItems: "center" 
                            }}>
                            <CardContent>
                                <AirplanemodeActiveIcon sx={{fontSize: "50px", color: "#009688"}} />
                                <Typography variant="h6" gutterBottom>AIR CARGO</Typography>
                                <Typography>
                                    Enjoy our speedy delivery at affordable cost. We understand your items can be time sensitive so we go out of our way to bring this services to you.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={10} md={4}>
                        <Card align="center" sx={{ height: 250, display: "flex", alignItems: "center" }}>
                            <CardContent>
                                <LocalShippingIcon sx={{fontSize: "50px", color: "#009688"}} />
                                <Typography variant="h6" gutterBottom>DOOR TO DOOR DELIVERY</Typography>
                                <Typography>
                                    Shipments from our USA facility are sorted at our Lagos office and prepared for customer pick up. At customers request and for convenience, we offer door to door deliveries nationwide across Nigeria. 
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <WavyBorderBottom />
        </Paper>
    )
}

export default Services