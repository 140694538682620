import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useAxiosPublic from '../../../hooks/useAxiosPublic'

import {
  Loading
} from '../../../common/index'

import {
  Stack,
  Button,
  Container,
  Typography
} from "@mui/material"

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelIcon from '@mui/icons-material/Cancel';

const EmailActivation = () => {
  const axios                   = useAxiosPublic()
  const { code }                = useParams()
  const [ loading, setLoading ] = useState(true)
  const [ data, setData ]       = useState(false)

  useEffect(() => {
    const controller = new AbortController();
    const getData = async () => {
      try {
        setLoading(true)
        const response = await axios.get(`/email-confirmation/${code}`, {
          signal: controller.signal
        });
        console.log(response.data, 'response.data');
        setData( response.data )
        setLoading(false)
      } catch (err) {
        if (err.code === 'ERR_CANCELED') {
          console.log('Successfully aborted');
        } else {
          console.log(err.response.data.message, 'Error');
          setLoading(false)
        }
      }
    }
    getData()
    return () => { 
      controller.abort();
    }
  }, [axios, code])

  return (
    <Container maxWidth="xs" sx={{ py: "30px" }}>
      {loading && <Loading />}
      {!loading && data && <Stack alignItems="center" justifyContent="center">
        <TaskAltIcon 
          sx={{
            color: '#00bd00',
            fontSize: 65
          }} 
        />
        <Typography variant="h5" align="center" my={4}>Thank you for confirming your email</Typography>
        <Button
          type="submit"
          size="large"
          href="/login"
        >
          Sign in
        </Button>
      </Stack>}
      {!loading && !data && <Stack alignItems="center" justifyContent="center">
        <CancelIcon 
          sx={{
            color: 'red',
            fontSize: 65
          }} 
        />
        <Typography variant="h5" align="center" my={4}>An error occurred</Typography>
        <Button
          type="submit"
          size="large"
          href="/contact"
        >
          Contact us
        </Button>
      </Stack>}
    </Container>
  )
}

export default EmailActivation