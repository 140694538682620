import cargoImage from '../../../assets/images/cargo-ship.jpg'
import deliveryMan from '../../../assets/images/jhare-delivery-man.jpg'
import safeDelivery from '../../../assets/images/safe-delivery.jpg'
import { useTheme } from '@mui/material/styles'

import {
  Avatar,
  Typography,
  Container,
  Grid,
  Box
} from "@mui/material"

const Highlight = () => {
    const theme = useTheme()
    const fontWeight = theme.typography.fontWeightBold
    return (
        <Container>
            <Grid container alignItems="center" justifyContent="space-between" my={15}>
                <Grid item xs={12} smd={5.5} mb={3}>
                    <Box>
                        <Typography variant="h4" gutterBottom sx={{fontWeight}}>Sea cargo</Typography>
                        <Typography>
                            Containers leave to Nigeria every week with guaranteed delivery of your shipments in 6 to 8 weeks. This includes AUTO SHIPPING. We offer auto deliveries from anywhere in the USA to Nigeria at an affordable prices.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} smd={5.5}>
                    <Avatar variant="square" src={cargoImage} srcSet={cargoImage} sx={{width: "100%", height: "auto", borderRadius: "14px"}} />
                </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between" flexDirection={{ smd: "row-reverse" }} mb={15}>
                <Grid item xs={12} smd={5.5} mb={3}>
                    <Box>
                        <Typography variant="h4" gutterBottom sx={{fontWeight}}>We deliver it safely</Typography>
                        <Typography>
                            After your precious shipments are received or picked up by us, we carefully reconsolidate them for shipping. We do not spare cost of proper packaging for customers shipments. Reboxing and consolidating are free to help mitigate the cost for our customers. Your smile thrills our heart at Jhare Express.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} smd={5.5}>
                    <Avatar variant="square" src={deliveryMan} srcSet={deliveryMan} sx={{width: "100%", height: "auto", borderRadius: "14px"}} />
                </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between" mb={15}>
                <Grid item xs={12} smd={5.5} mb={3}>
                    <Box>
                        <Typography variant="h4" gutterBottom sx={{fontWeight}}>Consolidation</Typography>
                        <Typography>
                            In order to save cost for our customers we take our time to break down larger shipping containers, boxes and packs. Repack and neatly reseal our customer packages for delivery.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} smd={5.5}>
                    <Avatar variant="square" src={safeDelivery} srcSet={safeDelivery} sx={{width: "100%", height: "auto", borderRadius: "14px"}} />
                </Grid>
            </Grid>
        </Container>
    )
}

export default Highlight