import { Link } from 'react-router-dom'
import headerImage from '../../../assets/images/shipping-bro.png'

import {
    Avatar,
    Stack,
    Typography,
    Container,
    Button,
    Grid
} from "@mui/material"

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

const navQuoteItem = {name: 'Get Quote', to: '/quote'}

const Header = () => {
    return (
        <Container sx={{py: {xs: "3em", sm: "5em", md: "3em" }}}>
            <Grid container alignItems="center" spacing={3} direction={{ xs: "column-reverse", md: "row" }}>
                <Grid item xs={12} md={6}>
                    <Typography component="h1" variant="h3" gutterBottom>Shipping from USA to Nigeria</Typography>
                    <Stack flexDirection="row" alignItems="center" gap="5px" flexWrap="wrap" mb="2em">
                        <Typography>Air & Sea cargo</Typography>
                        <FiberManualRecordIcon sx={{fontSize: "10px"}} />
                        <Typography>Shop for me</Typography>
                        <FiberManualRecordIcon sx={{fontSize: "10px"}} />
                        <Typography>Door to Door Delivery</Typography>
                    </Stack>
                    <Button component={Link} to={navQuoteItem.to} size="large">{navQuoteItem.name}</Button>
                </Grid>
                <Grid item xs={12} sm={6} display={{ xs: "none", md: "block" }}>
                    <Avatar variant="square" src={headerImage} srcSet={headerImage} sx={{width: "100%", height: "auto", borderRadius: "14px"}} />
                </Grid>
            </Grid>
        </Container>
    )
}

export default Header