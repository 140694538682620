import useAxiosPublic from '../hooks/useAxiosPublic';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const axios = useAxiosPublic();
    const refresh = async () => {
        const response = await axios.get('/refresh-token', {
            withCredentials: true
        });
        setAuth(response.data);
        return response.data.token;
    }
    return refresh;
};

export default useRefreshToken;