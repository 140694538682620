import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

import {
    Button,
    Typography,
    Container
} from "@mui/material"

const navQuoteItem = {name: 'Request a quote', to: '/quote'}

const Book = () => {
    const theme = useTheme()
    return (
        <Container align="center" sx={{py: 10}}>
            <Typography variant="h4" sx={{fontWeight: theme.typography.fontWeightBold}}>Want to ship to Nigeria?</Typography>
            <Typography variant="h5" pt={3} pb={5}>We can help you buy your items and ship them to Nigeria from the USA.</Typography>
            <Button component={Link} to={navQuoteItem.to} size="large">{navQuoteItem.name}</Button>
        </Container>
    )
}

export default Book