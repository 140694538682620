import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import { ThemeProvider, CssBaseline } from '@mui/material';

import Login from './pages/login';
import SignUp from './pages/signup';
import Account from './pages/account';
import Home from './pages/home';
import Track from "./pages/track";
import Quote from "./pages/quote";
import NotFound from "./pages/404";
import Unauthorized from "./pages/unauthorized";
import Shipments from "./pages/shipments";
import AllShipments from "./pages/shipments/all";
import NewShipment from "./pages/shipments/new";
import EditShipment from "./pages/shipments/edit";
import Shipment from "./pages/shipments/shipment";
import Users from "./pages/users";
import AllUsers from "./pages/users/all";
import NewUsers from "./pages/users/new";
import EditUser from "./pages/users/edit";
import Tracking from "./pages/tracking";
import AllTracking from "./pages/tracking/all";
import NewTracking from "./pages/tracking/new";
import EditTracking from "./pages/tracking/edit";
import SingleTracking from "./pages/tracking/single";
import PrivacyPolicy from "./pages/privacy-policy";
import About from "./pages/about";
import Contact from "./pages/contact";
import PasswordReset from "./pages/password-reset";
import EmailActivation from "./pages/email-activation";
import { AuthProvider } from './context/AuthProvider';
import { TitleProvider } from './context/TitleProvider';

import {
  theme,
  PublicLayout,
  PrivateLayout,
  RequireAuth,
  PersistLogin,
  ScrollToTop
} from "./common";

import {
  ROLES
} from './config/index';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <AuthProvider>
          <TitleProvider>
            <ScrollToTop />
            <Routes>

              { /* Public routes */ }
              <Route path="/" element={<PublicLayout />}>
                <Route index element={<Home />} />
                <Route path="login" element={<Login />}/>
                <Route path="signup" element={<SignUp />}/>
                <Route path="track" element={<Track />}/>
                <Route path="quote" element={<Quote />}/>
                <Route path="privacy-policy" element={<PrivacyPolicy />}/>
                <Route path="about" element={<About />}/>
                <Route path="contact" element={<Contact />}/>
                <Route path="password-reset" element={<PasswordReset />}/>
                <Route path="email-activation/:code" element={<EmailActivation />}/>
                <Route path="unauthorized" element={<Unauthorized />} />
                <Route path="*" element={<NotFound />}/>
              </Route>

              <Route element={<PersistLogin />}>
                { /* Private user routes */ }
                <Route element={<RequireAuth allowedRoles={[ROLES.user.key, ROLES.admin.key]} />}>
                  <Route path="/" element={<PrivateLayout />}>
                    <Route path="account" element={<Account />}/>
                    <Route path="shipments" element={<Shipments />}>
                      <Route index element={<AllShipments />} />
                      <Route path="received" element={<AllShipments />} />
                      <Route path="shipped" element={<AllShipments />} />
                      <Route path="delivered" element={<AllShipments />} />
                      <Route path="on-hold" element={<AllShipments />} />
                      <Route path=":id" element={<Shipment />} />
                    </Route>
                    <Route path="*" element={<NotFound />}/>
                  </Route>
                </Route>

                { /* Admin routes */ }
                <Route element={<RequireAuth allowedRoles={[ROLES.admin.key]} />}>
                  <Route path="/" element={<PrivateLayout />}>
                    <Route path="shipments" element={<Shipments />}>
                      <Route path="rts" element={<AllShipments />} />
                      <Route path="new" element={<NewShipment />} />
                      <Route path="edit/:id" element={<EditShipment />} />
                      <Route path=":id" element={<Shipment />} />
                    </Route>
                    <Route path="users" element={<Users />}>
                      <Route index element={<AllUsers />} />
                      <Route path="new" element={<NewUsers />} />
                      <Route path=":id" element={<EditUser />} />
                    </Route>
                    <Route path="tracking" element={<Tracking />}>
                      <Route index element={<AllTracking />} />
                      <Route path="shipped" element={<AllTracking />} />
                      <Route path="delivered" element={<AllTracking />} />
                      <Route path="new" element={<NewTracking />} />
                      <Route path="edit/:id" element={<EditTracking />} />
                      <Route path=":id" element={<SingleTracking />} />
                    </Route>
                    <Route path="*" element={<NotFound />}/>
                  </Route>
                </Route>
              </Route>

            </Routes>
          </TitleProvider>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
