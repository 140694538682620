import React from 'react'

import { 
  Stack,
  Button,
  Typography,
  Container
} from "@mui/material";

import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';

const Unauthorized = () => {
  return (
    <Container maxWidth="xs" sx={{ py: "30px" }}>
      <Stack alignItems="center" justifyContent="center">
        <DesktopAccessDisabledIcon sx={{fontSize: 60}} />
        <Typography variant="h5" align="center" m={4}>You're not authorized to view this page.</Typography>
        <Button
          type="submit"
          size="large"
          href="/shipments"
        >
          Go Home
        </Button>
      </Stack>
    </Container>
  )
}

export default Unauthorized