import { useState, useLayoutEffect } from 'react'

const useElementSize = (ref) => {
    const [size, setSize] = useState({width: 0, height: 0});
    useLayoutEffect(() => {
        const updateSize = () => {
            if( ref.current ){
                setSize({
                    width: ref.current.clientWidth, 
                    height: ref.current.clientHeight
                });
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [ref]);
    return size;
}

export default useElementSize