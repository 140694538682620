import { useNavigate } from 'react-router-dom';
import useAxiosPublic from './useAxiosPublic';
import useAuth from "./useAuth";

const useLogout = () => {
    const { setAuth } = useAuth();
    const axios = useAxiosPublic();
    const navigate = useNavigate()

    const logout = () => {
        setAuth({});
        try {
            axios.get('/logout');
            navigate('/')
        } catch (err) {
            console.error(err);
        }
    }

    return logout;
}

export default useLogout