import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import useTitle from '../../../../hooks/useTitle';
import useAuth from '../../../../hooks/useAuth';
import useSplitPathname from '../../../../hooks/useSplitPathname';

import {
  ROLES,
  shipmentStatuses
} from '../../../../config/index'

import {
  Alert,
  Loading,
  MuiRouterLink,
  PaginationLinks,
  ReactHookFormMuiAutocomplete,
} from '../../../../common/index'

import {
  Box,
  Stack,
  Container,
  Typography,
  Checkbox,
  Button
} from '@mui/material'

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LocationSearchingOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';

const HorizontalStack = styled(Stack)(({ theme }) => ({
  alignItems: "flex-start",
  flexDirection: "row",
  flexWrap: "nowrap",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1)
}))

const AllShipments = () => {
  const { setTitle }                      = useTitle();
  const { auth }                          = useAuth();
  const path                              = useSplitPathname();
  const shipmentID                        = path[1];
  const theme                             = useTheme();
  const axios                             = useAxiosPrivate();
  const location                          = useLocation();
  const navigate                          = useNavigate();
  const [ loading, setLoading ]           = useState(true);
  const searchInput                       = useRef();
  const [ shipmentData, setShipmentData ] = useState({
    results: [],
    pages: 0
  });
  const [ checkAll, setCheckAll ]         = useState(false);
  const [ trackingData, setTrackingData ] = useState([]);
  const [ selectedShipments, setSelectedShipments ] = useState([]);
  const [ refreshShipments, setRefreshShipments ]   = useState(0);
  const [ alert, setAlert ]               = useState({open: false});
  const { open, message, severity }       = alert;
  const {
    handleSubmit, 
    control,
  } = useForm();

  useEffect(() => {
    let title;
    if (shipmentID === 'received') {
      title = 'Received Shipments'
    } else if (shipmentID === 'shipped') {
      title = 'Shipped Shipments'
    } else if (shipmentID === 'delivered') {
      title = 'Delivered Shipments'
    } else if (shipmentID === 'on-hold') {
      title = 'On hold Shipments'
    } else if (shipmentID === 'rts') {
      title = 'RTS Shipments'
    } else {
      title = 'All Shipments'
    }
    setTitle(title)
  }, [setTitle, shipmentID])

  useEffect( () => {
    const controller = new AbortController();
    const getShipmentData = async () => {
      try {
        setLoading(true);
        const params = new URLSearchParams(location.search);
        const page   = parseInt(params.get('page') || '1', 10);
        const search = params.get('search');    
        if (! search) {
          searchInput.current.value = '';
        } else {
          searchInput.current.value = search;
        }
        // Clear checked shipments
        setCheckAll(false);
        setSelectedShipments([]);
        const status   = shipmentID;
        const response = await axios.get(`/shipments${shipmentID && !isNaN(shipmentID) ? `/${shipmentID}` : ''}`, {
          signal: controller.signal,
          params: {
            per_page: 18,
            page,
            status,
            search
          }
        });
        setShipmentData( response.data );
        setLoading(false);
      } catch (err) {
        if (err.code !== 'ERR_CANCELED') {
          //console.log(err.response.data);
        }
      }
    }
    getShipmentData()
    return () => controller.abort()
  }, [axios, shipmentID, location.search, refreshShipments])

  useEffect(() => {
    const controller = new AbortController();
    const getTrackingData = async () => {
      try {
        const response = await axios.get('/tracking', {
          signal: controller.signal,
          params: {
            count: 0
          }
        });
        const results = response.data.map((data) => ({
          value: data.ID,
          label: `${data.tracking_number}(${data.ID})`
        }))
        setTrackingData( results );
      } catch (err) {
        if (err.code !== 'ERR_CANCELED') {
          //console.log(err?.response.data, 'Error');
        }
      }
    }
    getTrackingData();
    return () => controller.abort()
  }, [axios, location.key])

  let controller;
  const getTrackingData = async (e, inputValue) => {
    if (controller) {
      controller.abort()
    }
    controller = new AbortController();
    try {
      const response = await axios.get('/tracking', {
        signal: controller.signal,
        params: {
          search: inputValue,
          count: 0,
          per_page: inputValue ? -1 : 10
        }
      })
      const results = response.data.map((data) => ({
        value: data.ID,
        label: `${data.tracking_number}(${data.ID})`
      }))
      setTrackingData( results )
    } catch (err) {
      if (err.code !== 'ERR_CANCELED') {
        //console.log(err?.response?.data, 'Error');
      }
    }
  }

  const onSubmit = async e => {
    e.preventDefault();
    const params = new URLSearchParams(location.search);
    params.set('search', searchInput.current.value);
    navigate(`?${params.toString()}`);
  }

  const onSubmit2 = async formData => {
    if (! formData.trackingNumber?.value) {
      setAlert({
        open: true,
        message: 'Please select a tracking number',
        severity: 'error'
      })
      return;
    }
    if (! selectedShipments.length) {
      setAlert({
        open: true,
        message: 'Please select at least one shipment',
        severity: 'error'
      })
      return;
    }
    formData['id']        = formData.trackingNumber?.value
    formData['shipments'] = selectedShipments
    delete formData['trackingNumber']
    try {
      await axios.post(`/tracking`, {
        ...formData
      })
      // Clear checked shipments
      setCheckAll(false);
      setSelectedShipments([]);
      setRefreshShipments(new Date().getTime());
      setAlert({
        open: true,
        message: 'Saved successfully.',
        severity: 'success'
      })
    } catch (err) {
      setAlert({
        open: true,
        message: err.response.data.message,
        severity: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const deleteShipment = async (event, ID) => {
    event.preventDefault()
    if ( ! window.confirm('Are you sure you want to delete this data?') ) {
      return false;
    }
    try {
      await axios.delete(`/shipments/${ID}`)
      setShipmentData( {...shipmentData, results: shipmentData.results.filter( (shipment) => shipment.ID !== ID)} )
      setAlert({
        open: true,
        message: 'Deleted successfully.',
        severity: 'success'
      })
    } catch (err) {
      setAlert({
        open: true,
        message: err?.response?.data?.message || 'No server response',
        severity: 'error'
      })
    }
  }

  const editShipment = async (event, url) => {
    event.preventDefault()
    navigate(url)
  }

  const handleCheckboxChange = e => {
    if (e.target.checked) {
      setSelectedShipments([...selectedShipments, e.target.value])
    } else {
      setSelectedShipments(selectedShipments.filter((shipment) => shipment !== e.target.value))
    }
  }

  const handleSetCheckAll = e => {
    setCheckAll(!checkAll);
    if (e.target.checked) {
      const checkboxes = document.getElementsByName('checkbox');
      setSelectedShipments(Array.from(checkboxes).map(checkbox => checkbox.value));
    } else {
      setSelectedShipments([]);
    }
  }

  return (
    <Container disableGutters>
      <Alert
        privateLayout
        open={open}
        message={message}
        severity={severity}
        onClose={() => setAlert( {...alert, open: false} )}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
          gap: {xs: "10px", md: "20px"},
          flexDirection: {xs: "column-reverse", md: "row"}
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit2)}
          style={{
            display: "flex",
            gap: "10px",
            flex: 1
          }}
        >
          <Checkbox
            id="checkAll"
            name="checkAll"
            color="secondary"
            checked={checkAll}
            onChange={handleSetCheckAll}
          />
          <ReactHookFormMuiAutocomplete
            control={control}
            id="trackingNumber"
            name="trackingNumber"
            label="Tracking number"
            rules={{required: false}}
            options={trackingData}
            onInputChange={getTrackingData}
            textFieldProps={{
              size: "small"
            }}
            sx={{
              flex: 2,
              maxWidth: {smd: "100%", md: "55%", lg: "35%"}
            }}
          />
          <Button
            type="submit"
            style={{
              height: "40px"
            }}
          >
            Apply
          </Button>
        </form>
        <form
          onSubmit={(e) => onSubmit(e)}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            textAlign: "right",
            borderRadius: "5px",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "5px 10px",
          }}
        >
          <input
            type="text"
            name="search"
            id="search"
            ref={searchInput}
            placeholder="Search"
            style={{
              outline: "none",
              border: "none",
              width: "100%"
            }}
          />
          <button 
            type="submit"
            style={{
              padding: 0,
              margin: 0,
              lineHeight: 0,
              border: "none",
              background: "inherit",
              cursor: "pointer",
            }}
          >
            <SearchIcon sx={{ color: "#6c6c6c" }} />
          </button>
        </form>
      </Box>
      {loading && <Loading wrapperHeight='calc(100vh - 168px)' />}
      {!loading && <>
        <Stack flexDirection="row" alignItems="flex-start" gap="20px" flexWrap="wrap">
          {shipmentData.results.map((data)=> (
            <MuiRouterLink
              key={data.ID}
              sx={{
                position: "relative",
                width: {xs: "100%", md: "48.2%", lg: "31.8%" },
                padding: '20px 20px 15px',
                borderRadius: '5px',
                textDecoration: 'none',
                webkitTransition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                overflow: 'hidden'
              }}
              to={`/shipments/${data.ID}`}
            >
              <Box
                onClick={e => e.stopPropagation()}
                sx={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                }}
              >
                <Checkbox
                  name="checkbox"
                  value={data.ID}
                  color="secondary"
                  onChange={handleCheckboxChange}
                  checked={selectedShipments.includes(data.ID)}
                />
              </Box>
              <HorizontalStack>
                <CalendarMonthIcon sx={{ color: "#6c6c6c" }} />
                <Typography>{data.date && new Date(data?.date).toLocaleDateString()}</Typography>
              </HorizontalStack>
              <HorizontalStack>
                <PersonOutlineOutlinedIcon sx={{ color: "#6c6c6c" }} />
                <Typography>{data.recipient_name}</Typography>
              </HorizontalStack>
              <HorizontalStack>
                <LocationSearchingOutlinedIcon sx={{ color: "#6c6c6c" }} />
                <Typography>
                  {data.user_tracking}
                </Typography>
              </HorizontalStack>
              {auth?.roles?.some( role => role === ROLES.admin.key ) && <HorizontalStack>
                <ShareLocationIcon sx={{ color: "#6c6c6c" }} />
                <Typography>
                  {data.tracking_number}
                </Typography>
              </HorizontalStack>}
              <HorizontalStack>
                <MyLocationIcon sx={{ color: "#6c6c6c" }} />
                <Typography>{data.sender_tracking}</Typography>
              </HorizontalStack>
              <HorizontalStack>
                <LocalOfferOutlinedIcon sx={{ color: "#6c6c6c" }} />
                <Typography>{shipmentStatuses.find((el) => el.value === data.status)?.label}</Typography>
              </HorizontalStack>
              <Typography my={theme.spacing(1.5)}>{data.description}</Typography>
              {auth?.roles?.some( role => role === ROLES.admin.key ) && <Stack 
                flexDirection="row" 
                justifyContent="center" 
                gap="15px"
              >
                <EditIcon
                  fontSize="medium"
                  aria-label="edit"
                  sx={{
                    color: "#000",
                    padding: '5px',
                    boxSizing: 'content-box',
                    cursor: 'pointer'
                  }}
                  onClick={(e) => editShipment(e, `/shipments/edit/${data.ID}`)}
                />
                <DeleteIcon
                  fontSize="medium"
                  aria-label="delete"
                  sx={{
                    color: "#ff0000",
                    padding: '5px',
                    boxSizing: 'content-box',
                    cursor: 'pointer'
                  }}
                  onClick={(e) => deleteShipment(e, data.ID)}
                />
              </Stack>}
            </MuiRouterLink>
          ))}
        </Stack>
        <PaginationLinks
          mt={theme.spacing(5)}
          pages={shipmentData?.pages}
        />
      </>}
    </Container>
  )
}

export default AllShipments