import Header from './Header'
import Services from './Services'
import Highlight from './Highlight'
import Reviews from './Reviews'
import Book from './Book'

const Home = () => {
    return (
      <>
        <Header />
        <Services />
        <Highlight />
        <Reviews />
        <Book />
      </>
      )
}

export default Home