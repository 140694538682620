import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { siteURL } from '../../../../config/index';
import useTitle from '../../../../hooks/useTitle';

import {
  shipmentStatuses,
  formErrorMsg
} from '../../../../config/index'

import {
  Alert,
  FileUpload,
  ReactHookFormMuiInput,
  ReactHookFormMuiSelect,
  ReactHookFormMuiTextarea,
  ReactHookFormMuiAutocomplete
} from '../../../../common/index'

import {
  Button,
  Stack,
  Container,
} from "@mui/material";

const NewShipment = () => {
  const axios                                            = useAxiosPrivate()
  let location                                           = useLocation()
  const { setTitle }                                     = useTitle()
  const [ users, setUsers ]                              = useState([])
  const [ btnLoading, setBtnLoading ]                    = useState(false)
  const { 
    handleSubmit, 
    control, 
    reset, 
    formState: { 
      errors 
    } 
  }                                                      = useForm()
  const [ alert, setAlert ]                              = useState({open: false})
  const { open, message, severity }                      = alert

  useEffect(() => {
    setTitle('New Shipment')
  }, [setTitle])

  useEffect(() => {
    reset()
  }, [reset, location.key])

  useEffect(() => {
    const controller = new AbortController();
    const getUsers = async () => {
      try {
        const response = await axios.get(`${siteURL}/wp-json/wp/v2/users`, {
          signal: controller.signal
        });
        const users = response.data.map((user) => ({
          value: user.id, 
          label: `${user.display_name} (${user.email})`
        }))
        setUsers( users );
      } catch (err) {
        if (err.code !== 'ERR_CANCELED') {
          //console.log(err?.response?.data, 'Error');
        }
      }
    }
    getUsers();
    return () => {
      controller.abort();
    }
  }, [axios, location.key])

  let controller;
  const getUsers = async (e, inputValue, reason) => {
    if (reason === 'reset' || !inputValue || inputValue.length < 3) {
      return;
    }
    if (controller) {
      controller.abort()
    }
    controller = new AbortController();
    try {
      const response = await axios.get('/search-users', {
        signal: controller.signal,
        params: {
          search: inputValue
        }
      })
      setUsers(
        response.data
      )
    } catch (err) {
      if (err.code !== 'ERR_CANCELED') {
        //console.log(err.response.data, 'Error');
      }
    }
  }

  const onSubmit = async formData => {
    for (const key in formData) {
      if ( formData.hasOwnProperty( key ) ) {
        if ( ! formData[key] ) {
          // Remove empty data
          delete formData[key];
        } else if ( key === 'recipient_id' ){
          formData[key] = formData[key].value
        }
      }
    }
    try {
      setBtnLoading(true)
      await axios.post(
        `/shipments`, 
        {
          ...formData
        }, 
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      setAlert({
        open: true,
        message: 'Saved successfully.',
        severity: 'success'
      })
    } catch (err) {
      setAlert({
        open: true,
        message: err?.response?.data?.message || 'No server response',
        severity: 'error'
      })
    } finally {
      setBtnLoading(false)
    }
  }

  return (
    <Container disableGutters maxWidth="xs">
      <Alert
        privateLayout
        open={open}
        message={message}
        severity={severity}
        onClose={() => setAlert( {...alert, open: false} )}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <ReactHookFormMuiInput
            control={control}
            id="description"
            name="description"
            label="Short description"
            fullWidth
            autoComplete="description"
            autoFocus
            rules={{required: formErrorMsg.required}}
            error={!!errors.description}
            helperText={errors.description && errors.description?.message}
          />

          <ReactHookFormMuiInput
            control={control}
            id="sender_tracking"
            name="sender_tracking"
            label="Sender tracking number"
            fullWidth
            autoComplete="sender_tracking"
            rules={{required: false}}
            error={!!errors.sender_tracking}
            helperText={errors.sender_tracking && errors.sender_tracking?.message}
          />

          <ReactHookFormMuiSelect
            control={control}
            id="status"
            name="status"
            label="Shipment status"
            fullWidth
            autoComplete="status"
            rules={{required: formErrorMsg.required}}
            error={!!errors.status}
            helperText={errors.status && errors.status?.message}
            options={shipmentStatuses}
            defaultValue="received"
          />
          
          <ReactHookFormMuiAutocomplete
            control={control}
            label="Recipient"
            options={users}
            onInputChange={getUsers}
            id="recipient_id"
            name="recipient_id"
            rules={{required: false}}
            error={!!errors.note}
            helperText={errors.note && errors.note?.message}
          />

          <FileUpload
            control={control}
            name="attachment"
            rules={{required: false}}
            error={!!errors.attachment}
            helperText={errors.attachment && errors.attachment?.message}
            maxFileSize={15000000}
            acceptedFiles={['image/*']}
            dropzoneText={"Upload the package image(s)"}
          />

          <ReactHookFormMuiTextarea
            control={control}
            id="note"
            name="note"
            label="Note"
            fullWidth
            autoComplete="note"
            rules={{required: false}}
            error={!!errors.note}
            helperText={errors.note && errors.note?.message}
          />

          <Button
            type="submit"
            fullWidth
          >
            {btnLoading ? 'Please wait...' : 'Save'}
          </Button>
        </Stack>
      </form>
    </Container>
  )
}

export default NewShipment