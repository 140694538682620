import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import useTitle from '../../../../hooks/useTitle';
import useSplitPathname from '../../../../hooks/useSplitPathname';

import {
  shipmentStatuses
} from '../../../../config/index'

import {
  Alert,
  Loading,
  MuiRouterLink,
  PaginationLinks
} from '../../../../common/index'

import { 
  Container,
  Stack,
  Typography
} from '@mui/material'

import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const HorizontalStack = styled(Stack)(({ theme }) => ({
  alignItems: "flex-start",
  flexDirection: "row",
  flexWrap: "nowrap",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1)
}))

const AllTracking = () => {
  const { setTitle }                      = useTitle();
  const path                              = useSplitPathname();
  const trackingID                        = path[1];
  const theme                             = useTheme();
  const axios                             = useAxiosPrivate();
  const location                          = useLocation();
  const navigate                          = useNavigate();
  const [ loading, setLoading ]           = useState(true);
  const [ trackingData, setTrackingData ] = useState({
    results: [],
    pages: 0
  });
  const [ alert, setAlert ]               = useState({open: false});
  const { open, message, severity }       = alert;
  
  useEffect(() => {
    let title;
    if (trackingID === 'shipped') {
      title = 'Shipped Tracking'
    } else if (trackingID === 'delivered') {
      title = 'Delivered Tracking'
    } else {
      title = 'All Tracking'
    }
    setTitle(title)
  }, [setTitle, trackingID])

  useEffect( () => {
    const controller = new AbortController();
    const getTrackingData = async () => {
      try {
        setLoading(true)
        const query    = new URLSearchParams(location.search);
        const page     = parseInt(query.get('page') || '1', 10);
        const status   = trackingID;
        const response = await axios.get(`/tracking${trackingID && !isNaN(trackingID) ? `/${trackingID}` : ''}`, {
          signal: controller.signal,
          params: {
            per_page: 10,
            page,
            status
          }
        });
        setTrackingData( response.data )
        setLoading(false)
      } catch (err) {
        if (err.code !== 'ERR_CANCELED') {
          //console.log(err.response.data, 'Error');
        }
      }
    }
    getTrackingData()
    return () => {
      controller.abort();
    }
  }, [axios, trackingID, location.search])

  const deleteTracking = async (event, ID) => {
    event.preventDefault()
    if ( ! window.confirm('Are you sure you want to delete this data?') ) {
      return false;
    }
    try {
      await axios.delete(`/tracking/${ID}`)
      setTrackingData( {...trackingData, results: trackingData.results.filter( (tracking) => tracking.ID !== ID)} )
      setAlert({
        open: true,
        message: 'Deleted successfully.',
        severity: 'success'
      })
    } catch (err) {
      setAlert({
        open: true,
        message: err.response.data.message,
        severity: 'error'
      })
    }
  }

  const editTracking = async (event, url) => {
    event.preventDefault()
    navigate(url)
  }

  return (
    <Container disableGutters>
      <Alert
        privateLayout
        open={open}
        message={message}
        severity={severity}
        onClose={() => setAlert( {...alert, open: false} )}
      />
      {loading && <Loading privateLayout />}
      {!loading && <>
          <Stack flexDirection="row" alignItems="flex-start" gap="20px" flexWrap="wrap">
            {trackingData.results.map((data)=> (
              <MuiRouterLink
                key={data.ID}
                sx={{
                  width: {xs: "100%", md: "48.2%", lg: "31.8%" },
                  padding: '20px 20px 15px',
                  borderRadius: '5px',
                  textDecoration: 'none',
                  webkitTransition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                  overflow: 'hidden'
                }}
                to={`/tracking/${data.ID}`}
              >
                <HorizontalStack>
                  <CalendarMonthIcon sx={{ color: "#6c6c6c" }} />
                  <Typography>{data.date && new Date(data?.date).toLocaleString()}</Typography>
                </HorizontalStack>
                <HorizontalStack>
                  <ShareLocationIcon sx={{ color: "#6c6c6c" }} />
                  <Typography>
                    {data.tracking_number}
                  </Typography>
                </HorizontalStack>
                <HorizontalStack>
                  <LocalOfferOutlinedIcon sx={{ color: "#6c6c6c" }} />
                  <Typography>{shipmentStatuses.find((el) => el.value === data.status)?.label}</Typography>
                </HorizontalStack>
                <Typography
                  my={theme.spacing(1.5)}
                  sx={{
                    whiteSpace: "pre-wrap"
                  }}
                >
                  {data.tracking_info}
                </Typography>
                <Stack 
                  flexDirection="row" 
                  justifyContent="center" 
                  gap="15px"
                >
                  <EditIcon
                    fontSize="medium"
                    aria-label="edit"
                    sx={{
                      color: "#000",
                      padding: '5px',
                      boxSizing: 'content-box',
                      cursor: 'pointer'
                    }}
                    onClick={(e) => editTracking(e, `/tracking/edit/${data.ID}`)}
                  />
                  <DeleteIcon
                    fontSize="medium"
                    aria-label="delete"
                    sx={{
                      color: "#ff0000",
                      padding: '5px',
                      boxSizing: 'content-box',
                      cursor: 'pointer'
                    }}
                    onClick={(e) => deleteTracking(e, data.ID)}
                  />
                </Stack>
              </MuiRouterLink>
            ))}
          </Stack>
        <PaginationLinks
          mt={theme.spacing(5)}
          pages={trackingData?.pages}
        />
      </>}
    </Container>
  )
}

export default AllTracking